<template>
  <q-card flat bordered class="q-pa-md mt-4">
    <q-card-section>
      <p class="text-h6">Parámetros</p>
      <div class="row q-col-gutter-md">
        <q-input
          v-model="discountByRecurrence"
          class="col-4"
          type="number"
          step="0.01"
          label="Descuento por ser plan"
          :rules="[(val) => !!val || 'Debes ingresar el monto por ser plan']"
        ></q-input>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
export default {
  data() {
    return {
      discountByRecurrence: null,
    };
  },

  methods: {
    getData() {
      return {
        discountByRecurrence: this.discountByRecurrence,
      };
    },
    setData(data) {
      this.discountByRecurrence = data.discountByRecurrence;
    },
  },
};
</script>
