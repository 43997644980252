import Swal from 'sweetalert2';
const formatMoney = (amount, currency = 'Q ') => {
  try {
    let decimalCount = 2;
    let decimal = '.';
    let thousands = ',';
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      currency +
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

const formatDate = (dateStr) => {
  if (dateStr && dateStr.length > 0) {
    dateStr = dateStr
      .split('-')
      .map((item) => {
        if (parseInt(item) <= 9 && item.length === 1) return `0${item}`;
        else return item;
      })
      .join('-');
    if (dateStr.includes(' ')) dateStr = dateStr.replace(' ', 'T');
    else dateStr = `${dateStr}T00:00:00`;

    const date = new Date(dateStr);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  return '';
};

const formatISO = (dateStr) => {
  if (!dateStr || dateStr.length == 0) return '';

  let [day, month, year] = dateStr.split('/');

  let dateObj = new Date(year, month - 1, day);

  return dateObj.toISOString().slice(0, 10);
};

const toastError = (message) => {
  Swal.fire({
    title: message ?? 'Se ha producido un error',
    icon: 'error',
    showConfirmButton: false,
    toast: true,
    position: 'top-end',
    timer: 4000,
  });
};

const toastSuccess = (message) => {
  Swal.fire({
    title: message ?? 'Operación realizada correctamente',
    icon: 'success',
    showConfirmButton: false,
    toast: true,
    position: 'top-end',
    timer: 4000,
  });
};
const toastInfo = (message) => {
  Swal.fire({
    title: message ?? 'Info',
    icon: 'info',
    showConfirmButton: false,
    toast: true,
    position: 'top-end',
    timer: 4000,
  });
};

const decrementRange = (range) => {
  if (!range || !range.includes('-')) return '';
  const [start] = range.split('-');
  let [startHour, startMinutes] = start.split(':');
  let startHourDecrement = parseInt(startHour) - 1;

  if (parseInt(startHour) == 12) {
    startMinutes = startMinutes.replace('pm', 'am');
  }

  if (startMinutes.includes('pm') && startHourDecrement == 0)
    startHourDecrement = 12;

  return `${startHourDecrement}:${startMinutes}`;
};

const decrementStartRange = (range) => {
  if (!range || !range.includes('-')) return '';
  const [start, end] = range.split('-');
  let [startHour, startMinutes] = start.split(':');
  let startHourDecrement = parseInt(startHour) - 1;

  if (parseInt(startHour) == 12) {
    startMinutes = startMinutes.replace('pm', 'am');
  }

  if (startMinutes.includes('pm') && startHourDecrement == 0)
    startHourDecrement = 12;

  return `${startHourDecrement}:${startMinutes} - ${end}`;
};

const formatDateString = (dateString) => {
  const weekdays = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day);
  const weekday = weekdays[date.getDay()];
  const monthName = months[date.getMonth()];
  const dayNumber = date.getDate();

  return `${weekday} ${dayNumber} de ${monthName}`;
};

function htmlEntityDecode(message) {
  var element = document.createElement('div');
  element.innerHTML = message;
  return element.innerHTML;
}

export {
  formatMoney,
  formatDate,
  toastError,
  toastSuccess,
  toastInfo,
  decrementRange,
  formatDateString,
  decrementStartRange,
  htmlEntityDecode,
  formatISO,
};
