<template>
  <div>
    <div class="text-right mb-3 flex">
      <p class="text-h4 text-bold flex-1 text-left">Agregar Paquete/Plan</p>

      <div>
        <q-btn
          color="primary"
          icon="add"
          class="mr-3"
          label="Agregar Cliente"
          @click="$refs.modalAddCustomer.setVisibleModal(true)"
        >
        </q-btn>

        <q-btn
          label="Regresar"
          color="secondary"
          icon="o_keyboard_backspace"
          @click="$router.back()"
        ></q-btn>
      </div>
    </div>

    <div>
      <q-form class="pb-4" @submit="sendData" ref="form">
        <AddPlanCustomer ref="customer"></AddPlanCustomer>

        <AddPlanDishes ref="dishes"></AddPlanDishes>

        <!-- <AddPlanExtras ref="extras"></AddPlanExtras> -->

        <AddPlanDataDelivery ref="dataDelivery"></AddPlanDataDelivery>

        <AddPlanTotals ref="totals"></AddPlanTotals>

        <div class="text-center q-mt-lg">
          <q-btn
            color="primary"
            label="Guardar"
            icon="o_save"
            type="submit"
            unelevated
          ></q-btn>
        </div>
      </q-form>
    </div>
    <modal-add-customer
      ref="modalAddCustomer"
      @customerAdded="$refs.customer.getCustomers()"
    ></modal-add-customer>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import ModalAddCustomer from '../../components/ModalAddCustomer.vue';
import MessagesMixin from '../../mixins/MessagesMixin';
import AddPlanCustomer from './AddPlanCustomer.vue';
import AddPlanDataDelivery from './AddPlanDataDelivery.vue';
import AddPlanDishes from './AddPlanDishes.vue';
// import AddPlanExtras from './AddPlanExtras.vue';
import store from './AddPlanStore';
import AddPlanTotals from './AddPlanTotals.vue';

export default {
  mixins: [MessagesMixin],
  components: {
    AddPlanDishes,
    AddPlanCustomer,
    AddPlanDataDelivery,
    AddPlanTotals,
    ModalAddCustomer,
    // AddPlanExtras,
  },
  data: () => {
    return {
      order: {
        oneFreeDelivery: false,
        twoFreeDelivery: false,
        deliveryTime: null,

        discountByRecurring: 0,
      },
      item: {
        dishes: [],
      },
      datesDelivery: [],
      loading: false,
      quantity: 1,

      typeDelivery: '1',
    };
  },
  created() {
    store.dispatch('getDishPrices', this);
    store.dispatch('getDiscountRecurrence', this);
  },

  methods: {
    sendData(e) {
      e.preventDefault();

      let customer = this.$refs.customer.getData();
      let dishes = this.$refs.dishes.getData();
      let dataDelivery = this.$refs.dataDelivery.getData();

      let formData = new FormData();
      formData.append('action', 'create_order_plan');
      let packages = store.getters.packages.map((item) => {
        return {
          ...item,
          dishes: item.dishes
            .filter((elem) => elem.quantity > 0)
            .map((item) => {
              return {
                ...item,
                price: store.getters.getPriceDish,
              };
            }),
        };
      });

      let order = {
        ...dishes,
        ...dataDelivery,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        nit: customer.nit,
        phone: customer.phone,
        deliveryAmount: store.getters.priceDelivery,
        idChef: dishes.chef.id,
        totalDishes: store.getters.getQuantityDishes,
        discountByRecurring: store.getters.discountByRecurring,
        foodTotal: store.getters.foodTotal,
        plan: {
          productId: dishes.plan.idProduct,
          planId: dishes.plan.id,
          quantity: 1,
          price: store.getters.getPriceDish,
          packages: [...packages],
        },
        discountKasera: store.getters.discountKasera,
        discountChef: store.getters.discountChef,
        extraAmount: store.getters.extraAmount,
        enlargedProtein: store.getters.enlargedProteinAmount,
      };

      console.log(order);
      formData.append('data', JSON.stringify(order));

      if (order.totalDishes == 0) {
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Debes seleccionar al menos un platillo',
          confirmButtonText: 'Cerrar',
        });
        return;
      }

      this.$http
        .post(this.url, formData)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.message === 'success') {
              this.messageSuccess(
                `Paquete #${res.data.code} agregado correctamente`
              );
              this.sendNotification(res.data.id, dishes.chef.id, res.data.code);
              store.commit('resetState', this);

              this.$refs.form.reset();
              this.$refs.form.resetValidation();
              this.$refs.customer.resetState();
              this.$refs.dishes.resetState();
              this.$refs.dataDelivery.resetState();
              this.$refs.totals.resetState();

              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: '',
                text: 'Lo sentimos, se ha producido un error al procesar la orden',
                confirmButtonText: 'Cerrar',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: 'Lo sentimos, se ha producido un error al procesar la orden',
              confirmButtonText: 'Cerrar',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'Lo sentimos, se ha producido un error',
            confirmButtonText: 'Cerrar',
          });
        })
        .finally(() => (this.loading = false));
    },
    sendNotification(orderId, chefId, code) {
      let body = {
        id: orderId,
        code,
        meta_data: [
          {
            key: '_dokan_vendor_id',
            value: chefId,
          },
        ],
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      };
      let endPoint = `${process.env.VUE_APP_API_APP}/orders/notification/es`;

      fetch(endPoint, requestOptions)
        .then((response) => {
          if (!response.ok) {
            alert('No se ha logrado enviar la notificación al cocinero');
          }
          return response.json();
        })
        .catch((error) => {
          console.log(error);
          alert('No se ha logrado enviar la notificación al cocinero');
        });
    },
  },
};
</script>
