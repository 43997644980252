import { formatDateString, htmlEntityDecode } from '../../js/util';

const formatPackagesForChef = (packages) => {
  return packages.map((item) => {
    return [
      {
        text: `Paquete #${item.code} - ${item.product}`,
        style: 'titleItem',
      },

      {
        stack: [
          {
            text: [
              { text: `Fecha de entrega: `, bold: true },
              {
                text: formatDateString(item.deliveryDateChef),
                width: 'auto',
                color: '#555',
              },
            ],
            style: ['mb1'],
          },
        ],
      },

      {
        stack: [
          {
            text: [
              { text: `Hora de entrega: `, bold: true },
              {
                text: item.deliveryTimeChef,
                width: 'auto',
                color: '#555',
              },
            ],
            style: ['mb1'],
          },
        ],
      },

      {
        stack: [
          {
            text: [
              { text: `Cantidad de platillos: `, bold: true },
              {
                text: item.totalDishes,
                width: 'auto',
                color: '#555',
              },
            ],
            style: ['mb1'],
          },
        ],
      },

      {
        stack: [
          {
            text: [
              { text: `Total a facturar: `, bold: true },
              {
                text: htmlEntityDecode(item.currency) + item.totalBilling,
                width: 'auto',
                color: '#555',
              },
            ],
            style: ['mb1'],
          },
        ],
      },

      {
        stack: [
          {
            text: [
              { text: `NIt del cliente: `, bold: true },
              {
                text: item.nit,
                width: 'auto',
                color: '#555',
              },
            ],
            style: ['mb1'],
          },
        ],
      },

      {
        text: 'Platillos',
        style: ['dishes'],
      },

      {
        separator: ')',
        ul: formatDishes(item),
      },
      {
        ...(item.data.packages[0].extras?.length > 0 && {
          text: 'Extras',
          style: ['dishes'],
        }),
      },
      {
        separator: ')',
        ul: formatExtras(item),
      },

      {
        ...(item.noteChef && {
          text: 'Comentario al chef',
          bold: true,
        }),
      },
      {
        ...(item.noteChef && {
          text: item.noteChef,
          style: 'mb2',
        }),
      },

      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 595 - 80,
            y2: 5,
            lineWidth: 1,
          },
        ],
      },
    ];
  });
};

const formatPackagesForDelivery = (packages, address, phone) => {
  return packages.map((item) => {
    return [
      {
        text: `Paquete #${item.code}`,
        style: ['titleItem', 'mb2'],
      },

      {
        columns: [
          {
            columns: [
              {
                text: 'Fecha de entrega: ',
                bold: true,

                width: 'auto',
              },
              {
                text: formatDateString(item.deliveryDate),
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
            style: ['mb1'],
          },

          {
            columns: [
              {
                text: 'Hora de entrega: ',
                bold: true,

                width: 'auto',
              },
              {
                text: item.deliveryTime,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
          },
        ],
      },

      {
        columns: [
          {
            columns: [
              {
                text: 'Cantidad de platillos: ',
                bold: true,

                width: 'auto',
              },
              {
                text: item.totalDishes,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
            style: ['mb1'],
          },

          {
            columns: [
              {
                text: 'Nota de entrega: ',
                bold: true,

                width: 'auto',
              },
              {
                text: item.noteDelivery,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
          },
        ],
      },

      {
        columns: [
          {
            columns: [
              {
                text: 'Dirección cliente: ',
                bold: true,

                width: 'auto',
              },
              {
                text: `${item.address} ${item.address2}`,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
            style: ['mb1'],
          },

          {
            columns: [
              {
                text: 'Dirección chef: ',
                bold: true,

                width: 'auto',
              },
              {
                text: address || item.addressChef,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
          },
        ],
      },

      {
        columns: [
          {
            columns: [
              {
                text: 'Teléfono cliente: ',
                bold: true,

                width: 'auto',
              },
              {
                text: `${item.phoneCustomer}`,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
            style: ['mb1'],
          },

          {
            columns: [
              {
                text: 'Teléfono chef: ',
                bold: true,

                width: 'auto',
              },
              {
                text: phone || item.phoneChef,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
          },
        ],
      },

      {
        columns: [
          {
            columns: [
              {
                text: 'Nombre cliente: ',
                bold: true,

                width: 'auto',
              },
              {
                text: `${item.name} ${item.lastName}`,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
            style: ['mb1'],
          },

          {
            ...((parseInt(item.pendingPayment) > 0 ||
              item.paymentMethodCode == 'cod') && {
              columns: [
                {
                  text: 'Pendiente de pago: ',
                  bold: true,

                  width: 'auto',
                },
                {
                  text: `${htmlEntityDecode(item.currency)}${
                    item.paymentMethodCode == 'cod'
                      ? item.total
                      : item.pendingPayment
                  } `,
                  width: 'auto',
                  color: '#555',
                },
              ],
              width: '50%',
              style: ['mb1'],
            }),
          },
        ],
      },

      {
        columns: [
          {
            columns: [
              {
                text: 'Método de pago: ',
                bold: true,

                width: 'auto',
              },
              {
                text: item.paymentMethod,
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
            style: ['mb1'],
          },

          {
            columns: [
              {
                text: 'Pagado: ',
                bold: true,

                width: 'auto',
              },
              {
                text: item.paidByCustomer === 'on' ? 'Si' : 'No',
                width: 'auto',
                color: '#555',
              },
            ],
            width: '50%',
            style: ['mb1'],
          },
        ],
      },

      {
        text: 'Platillos',
        style: ['dishes'],
      },

      {
        separator: ')',
        ul: formatDishes(item),
      },

      {
        ...(item.data.packages[0].extras?.length > 0 && {
          text: 'Extras',
          style: ['dishes'],
        }),
      },
      {
        separator: ')',
        ul: formatExtras(item),
      },

      {
        ...(item.noteChef && {
          text: 'Comentario al chef',
          bold: true,
        }),
      },
      {
        ...(item.noteChef && {
          text: item.noteChef,
          style: 'mb2',
        }),
      },

      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 595 - 80,
            y2: 5,
            lineWidth: 1,
          },
        ],
      },
    ];
  });
};

const formatDishes = (item) => {
  let stacks = [];

  item.data.packages.map((packageItem) => {
    packageItem.dishes.map((dish) => {
      stacks.push({
        stack: [
          {
            text: [
              { text: `X ${dish.quantity} - ${dish.name}`, bold: true },
              { text: ` ${dish.accompaniments?.split('||').join(',')}` },
              {
                ...(dish.comment && {
                  text: ` --- Comentario: ${dish.comment}`,
                  color: '#333',
                  italics: true,
                }),
              },
            ],
          },
        ],
        style: 'mb2',
      });
    });
  });

  return stacks;
};

// eslint-disable-next-line no-unused-vars
const formatExtras = (item) => {
  // let stacks = [];

  // item.data.packages.map((packageItem) => {
  //   packageItem.extras.map((extra) => {
  //     stacks.push({
  //       stack: [
  //         {
  //           text: [
  //             { text: `X ${extra.quantity} - ${extra.name}`, bold: true },
  //             {
  //               ...(extra.comment && {
  //                 text: ` --- Comentario: ${extra.comment}`,
  //                 color: '#333',
  //                 italics: true,
  //               }),
  //             },
  //           ],
  //         },
  //       ],
  //       style: 'mb2',
  //     });
  //   });
  // });

  // return stacks;
  return [];
};

export { formatPackagesForChef, formatPackagesForDelivery };
