<template>
  <q-card flat bordered class="q-mt-md q-pa-md mt-4">
    <q-card-section>
      <span class="text-h6">Datos de entrega</span>
    </q-card-section>
    <q-card-section>
      <div class="row q-col-gutter-md">
        <q-select
          v-model="order.department"
          label="Departamento"
          :options="departments"
          class="col-4"
          :rules="[(val) => !!val || 'Debes seleccionar el departamento']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          v-model="order.municipality"
          label="Municipio"
          :options="locations"
          class="col-4"
          :rules="[(val) => !!val || 'Debes seleccionar el municipio']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-input
          v-model="order.address"
          class="col-4"
          label="Dirección de entrega"
          :rules="[(val) => !!val || 'Debes ingresar la dirección de entrega']"
        />

        <q-input
          label="Fecha de entrega"
          :rules="[(val) => !!val || 'Debes seleccionar la fecha de entrega']"
          v-model="order.dateDelivery"
          @click="$refs.dateDelivery.show()"
          class="col-4"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                ref="dateDelivery"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="order.dateDelivery"
                  :locale="locale"
                  mask="DD/MM/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Cerrar" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-select
          v-model="order.deliveryTimeStart"
          label="Hora inicio de entrega"
          :options="getDeliveryTime()"
          class="col-4"
          :rules="[(val) => !!val || 'Debes seleccionar la hora de entrega']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          v-model="order.deliveryTimeEnd"
          label="Hora final de entrega"
          :options="getDeliveryTime()"
          class="col-4"
          :rules="[(val) => !!val || 'Debes seleccionar la hora de entrega']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-input
          label="Fecha de entrega chef"
          v-model="order.dateDeliveryChef"
          @click="$refs.dateDeliveryChef.show()"
          :rules="[
            (val) => !!val || 'Debes seleccionar la fecha de entrega del chef',
          ]"
          class="col-4"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                ref="dateDeliveryChef"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="order.dateDeliveryChef"
                  :locale="locale"
                  mask="DD/MM/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Cerrar" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-select
          v-model="order.deliveryTimeChef"
          label="Hora entrega del chef"
          :options="getDeliveryTime()"
          class="col-4"
          :rules="[(val) => !!val || 'Debes seleccionar la hora de entrega']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          v-model="order.paymentMethod"
          label="Método de pago"
          :options="paymentMethods"
          class="col-4"
          :rules="[(val) => !!val || 'Debes seleccionar el método de pago']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-input
          v-model="order.paymentPending"
          label="Pendiente de pago"
          type="number"
          class="col-4"
          step="0.01"
        />

        <q-input
          v-model="order.noteDelivery"
          class="col-4"
          label="Notas de entrega"
        />

        <q-select
          v-model="order.originType"
          label="Origen de adquisición"
          :options="originTypes"
          option-value="value"
          option-label="label"
          class="col-4"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-input
          v-if="order.originType?.value == 'ads'"
          v-model="order.codeAds"
          label="Código de anuncio"
          :rules="[(val) => !!val || 'Debes ingresar el codgio de anuncio']"
          class="col-4"
        />

        <q-select
          v-model="order.delivery"
          label="Envío"
          :options="deliveryOptions"
          option-value="value"
          option-label="label"
          class="col-4"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <div class="flex items-center cursor-pointer col-4">
          <q-checkbox
            name="is_plan"
            v-model="isRecurring"
            value="1"
            label="Es plan"
          />
          <i class="ri-information-line ml-3">
            <q-tooltip anchor="bottom middle" self="top middle">
              Si es plan se le descontrá Q{{
                store.getters.discountByRecurrence
              }}
              a cada platillo
            </q-tooltip>
          </i>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import MessagesMixin from '../../mixins/MessagesMixin';
import DateMixin from '../../mixins/DateMixin';
import store from './AddPlanStore';
import { formatISO } from '../../js/util';
import {
  originTypes,
  paymentMethods,
  deliveryOptions,
  departments,
} from './AddPlanUtil';
export default {
  mixins: [MessagesMixin, DateMixin],
  data() {
    return {
      store,
      order: {
        department: null,
        municipality: null,
        address: null,
        noteDelivery: '',
        dateDelivery: {},
        deliveryTimeStart: '',
        deliveryTimeEnd: '',
        dateDeliveryChef: {},
        deliveryTimeChef: '',
        paymentMethod: null,
        paymentPending: '',
        originType: null,
        codeAds: '',
        delivery: {
          value: '1',
          label: 'Un envío',
        },
      },
      originTypes,
      paymentMethods,
      deliveryOptions,
      locations: [],
      departments,
      isRecurring: false,
    };
  },
  created() {
    this.getLocations();
    this.setDefaultValues();
  },
  methods: {
    resetState() {
      this.order.department = null;
      this.order.municipality = null;
      this.order.address = null;
      this.order.noteDelivery = '';
      this.order.paymentMethod = null;
      this.order.paymentPending = '';
      this.order.originType = null;
      this.order.codeAds = '';
      this.order.delivery = {
        value: '1',
        label: 'Un envío',
      };
      this.isRecurring = false;
    },
    setDefaultValues() {
      const today = new Date();
      const nextMonday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + ((1 + 7 - today.getDay()) % 7)
      );
      this.order.dateDelivery = nextMonday
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '/');

      this.order.dateDeliveryChef = nextMonday
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '/');

      this.order.deliveryTimeStart = '10:00 am';
      this.order.deliveryTimeEnd = '2:00 pm';
    },
    getDeliveryTime() {
      let hours = [];

      for (let i = 0; i < 12; i++) {
        let hour = i === 0 ? 12 : i;
        for (let j = 0; j < 60; j += 30) {
          let minute = j.toString().padStart(2, '0');
          hours.push(`${hour}:${minute} am`);
        }
      }

      for (let i = 0; i < 12; i++) {
        let hour = i === 0 ? 12 : i;
        for (let j = 0; j < 60; j += 30) {
          let minute = j.toString().padStart(2, '0');
          hours.push(`${hour}:${minute} pm`);
        }
      }

      return hours;
    },

    getRangeDeliveryTime() {
      return `${this.order.deliveryTimeStart || ''} - ${
        this.order.deliveryTimeEnd || ''
      }`;
    },

    getLocations() {
      let formData = new FormData();
      formData.append('action', 'get_shipping_method');

      this.$http.post(this.url, formData).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            this.locations.push({
              value: item.id,
              label: item.location,
              price: parseFloat(item.price),
            });
          });

          store.commit(
            'setPriceDelivery',
            this.getPriceDelivery(this.order.municipality)
          );
        }
      });
    },
    getPriceDelivery(newValue) {
      let location = this.locations.find((item) => item.label == newValue);
      if (location) return location.price;

      return 0;
    },

    getData() {
      return {
        ...this.order,
        dateDelivery: formatISO(this.order.dateDelivery),
        dateDeliveryChef: formatISO(this.order.dateDeliveryChef),
        deliveryTime: this.getRangeDeliveryTime(),
        department: this.order.department.label,
        municipality: this.order.municipality.label,
        paymentMethod: this.order.paymentMethod?.value,
        originType: this.order.originType?.value,
        isRecurring: this.isRecurring,
      };
    },
  },
  computed: {
    customer() {
      return store.getters.customer;
    },
    totalOrder() {
      return store.getters.getPriceOrder;
    },
  },
  watch: {
    'order.municipality': {
      handler: function (newValue) {
        newValue &&
          store.commit(
            'setPriceDelivery',
            this.getPriceDelivery(newValue.label)
          );
      },
      deep: true,
    },

    'order.paymentMethod': {
      handler: function (newValue) {
        if (newValue && newValue.value == 'cod')
          this.order.paymentPending = store.getters.getPriceOrder;
      },
      deep: true,
    },

    'order.deliveryTimeStart': {
      handler: function (newValue) {
        let index = this.getDeliveryTime().findIndex(
          (item) => item == newValue
        );

        if (index > 0 && !this.order.deliveryTimeChef)
          this.order.deliveryTimeChef = this.getDeliveryTime()[index - 2];
      },
      deep: true,
    },
    'order.delivery': {
      handler: function ({ value }) {
        let priceDelivery = this.getPriceDelivery(
          this.order.municipality?.label
        );
        //Un envío gratis
        if (value == '2') priceDelivery = priceDelivery * 2;
        //Dos envio gratis
        else if (value == '0') priceDelivery = 0;

        store.commit('setPriceDelivery', priceDelivery);
      },
      deep: true,
    },
    isRecurring: {
      handler: function (newValue) {
        store.dispatch('setRecurring', newValue);
      },
      deep: true,
    },

    customer: {
      handler: function (newValue) {
        if (newValue) {
          this.order = {
            ...this.order,
            ...newValue,
            department: this.departments.find(
              (item) => item.label == newValue.department
            ),
            municipality: this.locations.find(
              (item) => item.label == newValue.municipality
            ),
            originType: this.originTypes.find(
              (item) => item.value == newValue.originType
            ),
            paymentMethod: this.paymentMethods.find(
              (item) => item.value == newValue.paymentMethod
            ),
          };
          this.isRecurring = newValue.isRecurring;
        }
      },
      deep: true,
    },
    totalOrder: {
      handler: function (newValue) {
        if (this.order.paymentMethod?.value === 'cod')
          this.order.paymentPending = newValue;
      },
      deep: true,
    },
  },
};
</script>
