export default {
  data() {
    return {};
  },
  methods: {
    messageSuccess(message) {
      this.$q.notify({
        color: 'success',
        message,
      });
    },
    messageError(message) {
      this.$q.notify({
        color: 'negative',
        message,
      });
    },
    messageInfo(message) {
      this.$q.notify({
        color: 'info',
        message,
      });
    },
  },
};
