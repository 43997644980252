<template>
  <q-form class="flex q-gutter-md mb-5" @submit="search">
    <q-input
      label="Fecha inicio"
      v-model="startDate"
      @click="$refs.startDatePicker.show()"
      filled
    >
      <template v-slot:append>
        <q-icon
          name="o_close"
          @click="startDate = null"
          class="cursor-pointer"
        ></q-icon>
        <q-icon class="cursor-pointer">
          <q-popup-proxy
            cover
            ref="startDatePicker"
            transition-show="scale"
            transition-hide="scale"
          >
            <q-date v-model="startDate" :locale="locale" mask="DD/MM/YYYY">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Cerrar" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>

    <q-input
      label="Fecha fin"
      v-model="endDate"
      @click="$refs.endDatePicker.show()"
      filled
    >
      <template v-slot:append>
        <q-icon
          name="o_close"
          @click="endDate = null"
          class="cursor-pointer"
        ></q-icon>
        <q-icon class="cursor-pointer">
          <q-popup-proxy
            ref="endDatePicker"
            cover
            transition-show="scale"
            transition-hide="scale"
          >
            <q-date v-model="endDate" mask="DD/MM/YYYY" :locale="locale">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Cerrar" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
    <div>
      <q-btn
        label="Filtrar"
        icon="o_search"
        color="primary"
        type="submit"
        :disable="!startDate || !endDate || validDates"
      ></q-btn>
    </div>
  </q-form>
</template>
<script>
import moment from 'moment';
import DateMixin from '../../mixins/DateMixin';
export default {
  mixins: [DateMixin],
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  methods: {
    search() {
      const startDate = moment(this.startDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
      const endDate = moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

      this.$emit('search', {
        startDate,
        endDate,
      });
    },
  },
  computed: {
    validDates() {
      if (
        moment(this.startDate, 'DD/MM/YYYY').isBefore(
          moment(this.endDate, 'DD/MM/YYYY')
        )
      ) {
        return false;
      }

      return true;
    },
  },
  watch: {},
};
</script>
