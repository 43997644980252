import Vuex from 'vuex';

export default new Vuex.Store({
  state: {
    isRecurring: false,
    discountByRecurring: 0,
    discountByRecurrence: 0,
    packages: [],
    dataPriceDishes: [],
    priceDishes: 0,
    priceDelivery: 0,
    customer: {},
    extras: [],
    discountKasera: 0,
    discountChef: 0,
    extraAmount: 0,
  },
  mutations: {
    resetState(state) {
      state.isRecurring = false;
      state.discountByRecurring = 0;
      state.packages = [];
      state.priceDishes = 0;
      state.priceDelivery = 0;
      state.customer = {};
    },
    setIsRecurring(state, newValue) {
      state.isRecurring = newValue;
    },
    setDiscountByRecurring(state, value) {
      state.discountByRecurring = value;
    },
    setDiscountByRecurrence(state, value) {
      state.discountByRecurrence = value;
    },
    setPackages(state, packages) {
      state.packages = packages;

      // if (packages.length > 0)
      //   state.extras =
      //     packages[0].extras?.map((item) => ({
      //       ...item,
      //       quantity: 0,
      //       comment: null,
      //     })) || [];
    },
    setDataPriceDishes(state, data) {
      state.dataPriceDishes = data;
    },
    setPriceDishes(state, price) {
      state.priceDishes = price;
    },
    setPriceDelivery(state, price) {
      state.priceDelivery = price;
    },
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setExtras(state, extras) {
      state.packages[0].extras = extras;
    },
    setDiscountKasera(state, newValue) {
      state.discountKasera = newValue;
    },
    setDiscountChef(state, newValue) {
      state.discountChef = newValue;
    },
    setExtraAmount(state, newValue) {
      state.extraAmount = newValue;
    },
  },
  actions: {
    getDiscountRecurrence({ commit }, context) {
      let formData = new FormData();
      formData.append('action', 'get_discount_by_recurrence');
      context.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            commit('setDiscountByRecurrence', res.data.value);
          } else this.messageError('Error al obtener datos');
        })
        .catch(() => {
          this.messageError('Error al obtener datos');
        });
    },
    calculatePrice({ state, commit, getters }) {
      const priceDishes = getters.getPriceDish * getters.getQuantityDishes;
      let discountByRecurring = 0;
      if (state.isRecurring) {
        discountByRecurring =
          getters.getQuantityDishes * state.discountByRecurrence;
      }
      commit('setPriceDishes', priceDishes);
      commit('setDiscountByRecurring', discountByRecurring);
    },
    getDishPrices({ commit }, context) {
      let formData = new FormData();
      formData.append('action', 'dish_prices');
      context.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            commit('setDataPriceDishes', res.data);
          } else this.messageError('Error al obtener datos');
        })
        .catch(console.log);
    },
    setRecurring({ commit, getters }, value) {
      commit('setIsRecurring', value);
      if (value) {
        let counter = 0;

        getters.packages.map((item) => {
          item.dishes.forEach((elem) => {
            if (elem.quantity > 0) {
              counter += elem.quantity;
            }
          });
        });
        if (counter > 0)
          commit(
            'setDiscountByRecurring',
            counter * getters.discountByRecurrence
          );
      } else commit('setDiscountByRecurring', 0);
    },
  },
  getters: {
    getQuantityDishes: (state) => {
      let counter = 0;

      state.packages.forEach((item) => {
        item.dishes
          .filter((item) => item.quantity > 0)
          .forEach((elem) => {
            counter += elem.quantity;
          });
      });

      return counter;
    },
    getPriceDish: (state, getters) => {
      let quantity = getters.getQuantityDishes;
      if (!quantity) return 0;
      if (quantity > state.dataPriceDishes.at(-1).quantity) {
        return state.dataPriceDishes.at(-1).price;
      }

      return state.dataPriceDishes.find((item) => item.quantity == quantity)
        .price;
    },
    getPriceOrder: (state, getters) => {
      let priceDishes = state.priceDishes;
      let priceDelivery = state.priceDelivery;
      let discountByRecurring = state.isRecurring
        ? getters.getQuantityDishes * state.discountByRecurrence
        : 0;

      return (
        priceDishes +
        priceDelivery -
        discountByRecurring +
        getters.enlargedProteinAmount -
        getters.discountKasera -
        getters.discountChef +
        getters.extraAmount
      );
    },
    foodTotal: (state, getters) => {
      return getters.getPriceOrder - state.priceDelivery;
    },

    existsExtras: (state) => {
      if (state.packages.length == 0) return false;
      return state.packages[0].extras?.length > 0;
    },
    getQuantityExtras: (state) => {
      let counter = 0;
      state.extras.forEach((item) => {
        counter += item.quantity;
      });
      return counter;
    },
    getPriceExtras: (state, getters) => {
      let quantity = getters.getQuantityExtras;
      if (!quantity) return 0;

      return state.extras.reduce(
        (acc, item) => acc + +item.price * item.quantity,
        0
      );
    },
    enlargedProteinAmount: (state) => {
      let amountPerDish = 4;
      return (
        state.packages[0]?.dishes
          ?.filter((item) => item.enlargedProtein)
          .reduce((acc, current) => acc + current.quantity, 0) *
          amountPerDish || 0
      );
    },
    isRecurring: (state) => state.isRecurring,
    discountByRecurring: (state) => state.discountByRecurring,
    discountByRecurrence: (state) => state.discountByRecurrence,
    packages: (state) => state.packages,
    dataPriceDishes: (state) => state.dataPriceDishes,
    priceDishes: (state) => state.priceDishes,
    priceDelivery: (state) => state.priceDelivery,
    customer: (state) => state.customer,
    discountKasera: (state) => +state.discountKasera || 0,
    discountChef: (state) => +state.discountChef || 0,
    extraAmount: (state) => +state.extraAmount || 0,
  },
});
