<template>
  <div class="flex">
    <Menu v-if="isLoggedIn"></Menu>
    <router-view style="flex: 1" class="p-5"></router-view>
    <loading></loading>
  </div>
</template>

<script>
import Menu from './components/Menu.vue';
import Loading from './components/Loading.vue';
export default {
  components: {
    Menu,
    Loading,
  },
  name: 'App',
  computed: {
    isLoggedIn() {
      return this.$route.name !== 'login';
    },
  },
};
</script>

<style></style>
