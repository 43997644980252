<template>
  <q-dialog v-model="isVisibleModal">
    <q-card>
      <detail-order :show-button-back="false" ref="detailOrder"></detail-order>
    </q-card>
  </q-dialog>
</template>
<script>
import DetailOrder from '../components/DetailOrder.vue';
export default {
  components: {
    DetailOrder,
  },
  data() {
    return {
      isVisibleModal: false,
      currentOrder: null,
    };
  },
  methods: {
    show(orderDetail) {
      this.isVisibleModal = true;
      this.currentOrder = orderDetail;
      this.$nextTick(() => {
        this.$refs.detailOrder.setOrder(orderDetail);
      });
    },
  },
};
</script>
