import { createRouter, createWebHashHistory } from 'vue-router';
import AmountDelivery from '../pages/AmountDelivery.vue';
import CustomerPlans from '../pages/Customers.vue';
import Plans from '../pages/Plans/Plans.vue';
import Packages from '../pages/Packages/Packages.vue';
import AddPlan from '../pages/AddPlan/AddPlan.vue';
import TypePlan from '../pages/TypePlan';
import Login from '../pages/Login';
import Configuration from '../pages/Configuration/Configuration.vue';
import PriceConfig from '../pages/PriceConfig/PriceConfig.vue';
import Home from '../pages/Home/Home.vue';
import store from '../store';

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/login', component: Login, name: 'login' },
  { path: '/type-plan', component: TypePlan, name: 'type-plan' },
  {
    path: '/amount-delivery',
    component: AmountDelivery,
    name: 'amount-delivery',
  },
  { path: '/customers', component: CustomerPlans, name: 'customers' },
  { path: '/plans', component: Plans, name: 'plans' },
  { path: '/packages', component: Packages, name: 'packages' },
  { path: '/plans/add', component: AddPlan, name: 'plansAdd' },
  { path: '/configuration', component: Configuration, name: 'configuration' },
  { path: '/price-config', component: PriceConfig, name: 'price-config' },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
