import './styles/quasar.scss';
import iconSet from 'quasar/icon-set/material-icons-outlined.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';

// To be used on app.use(Quasar, { ... })
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;

export default {
  config: {
    notify: {
      position: 'top',
      timeout: 2500,
      textColor: 'white',
      actions: [{ icon: 'close', color: 'white' }],
    },
  },
  plugins: {
    Notify,
    Loading,
  },
  iconSet: iconSet,
};
