<template>
  <q-card flat bordered class="q-pa-md mt-4">
    <q-card-section>
      <div class="q-col-gutter-md row q-pb-lg">
        <q-input
          v-model="discount.kasera"
          label="Descuento de Kasera"
          class="col-4"
          type="number"
        ></q-input>

        <q-input
          v-model="discount.chef"
          label="Descuento al chef"
          class="col-4"
          type="number"
        ></q-input>

        <q-input
          v-model="extraAmount"
          label="Cobro extra"
          class="col-4"
          type="number"
        ></q-input>
      </div>

      <div class="flex justify-between q-mt-md">
        <span class="text-grey-800 font-bold">Platillos</span>
        <span class="text-grey-800">{{ store.getters.priceDishes }}</span>
      </div>
      <q-separator spaced="md"></q-separator>
      <div class="flex justify-between mb-2">
        <span class="text-grey-800 font-bold">Costo de envío</span>
        <span class="text-grey-800">{{
          $formatNumber(store.getters.priceDelivery)
        }}</span>
      </div>
      <!-- <q-separator
        spaced="md"
        v-if="store.getters.getPriceExtras > 0"
      ></q-separator>
      <div
        class="flex justify-between mb-2"
        v-if="store.getters.getPriceExtras > 0"
      >
        <span class="text-grey-800 font-bold">Extras</span>
        <span class="text-grey-800">{{ store.getters.getPriceExtras }}</span>
      </div> -->
      <div
        v-if="
          store.getters.isRecurring && store.getters.discountByRecurring > 0
        "
      >
        <q-separator spaced="md"></q-separator>
        <div class="flex justify-between">
          <span class="text-grey-800 font-bold">Descuento por ser plan</span>
          <span class="text-grey-800">{{
            $formatNumber(store.getters.discountByRecurring)
          }}</span>
        </div>
      </div>

      <div v-if="store.getters.enlargedProteinAmount > 0">
        <q-separator spaced="md"></q-separator>
        <div class="flex justify-between">
          <span class="text-grey-800 font-bold">Proteína agrandada</span>
          <span class="text-grey-800">{{
            $formatNumber(store.getters.enlargedProteinAmount)
          }}</span>
        </div>
      </div>

      <div
        v-if="
          store.getters.discountKasera > 0 || store.getters.discountChef > 0
        "
      >
        <q-separator spaced="md"></q-separator>
        <div class="flex justify-between">
          <span class="text-grey-800 font-bold">Descuento</span>
          <span class="text-grey-800">{{
            $formatNumber(
              store.getters.discountKasera + store.getters.discountChef
            )
          }}</span>
        </div>
      </div>

      <div v-if="store.getters.extraAmount > 0">
        <q-separator spaced="md"></q-separator>
        <div class="flex justify-between">
          <span class="text-grey-800 font-bold">Cobro extra</span>
          <span class="text-grey-800">{{
            $formatNumber(store.getters.extraAmount)
          }}</span>
        </div>
      </div>

      <div class="divider"></div>
      <div class="flex justify-between">
        <span class="text-grey-900 font-bold">Total</span>
        <span class="text-grey-800">{{
          $formatNumber(store.getters.getPriceOrder)
        }}</span>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
import store from './AddPlanStore';
export default {
  props: {},
  data() {
    return {
      store,
      discount: {
        kasera: 0,
        chef: 0,
      },
      extraAmount: 0,
    };
  },

  created() {
    store.getters.isEditSubtotal;
  },
  watch: {
    discount: {
      handler: function (newValue) {
        store.commit('setDiscountKasera', +newValue.kasera || 0);
        store.commit('setDiscountChef', +newValue.chef || 0);
      },
      deep: true,
    },

    extraAmount: {
      handler: function (newValue) {
        store.commit('setExtraAmount', +newValue || 0);
      },
      deep: true,
    },
  },

  methods: {
    resetState() {
      this.discount = {
        kasera: 0,
        chef: 0,
      };
      this.extraAmount = 0;
    },
  },
};
</script>
