<template>
  <div>
    <div class="text-right mb-6 flex">
      <h1 class="text-4xl font-bold mr-auto">
        {{ getTitle() }}
      </h1>
      <button
        type="button"
        @click="view = 'ADD_PLAN'"
        v-if="view == 'PLANS'"
        class="btn btn-primary"
      >
        Agregar paquete
      </button>
      <button
        type="button"
        @click="cancelPlan"
        v-if="view == 'ADD_PLAN' || view == 'UPDATE_PLAN'"
        class="btn"
      >
        Regresar
      </button>
    </div>

    <div class="flex flex-col" v-if="view == 'PLANS'">
      <div class="-my-2">
        <div class="py-2 min-w-full align-middle inline-block">
          <div
            class="shadow min-w-full overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200 px-3 py-3">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Chef
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nombre
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    Opciones
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(item, index) in plans"
                  :key="index"
                  class="hover:bg-gray-100 dark:hover:bg-gray-700"
                  style="border-bottom: 1px solid #eee"
                >
                  <td class="px-6 py-3 whitespace-nowrap">
                    <div class="text-sm text-gray-900">{{ item.chef }}</div>
                  </td>
                  <td class="px-6 py-3 whitespace-nowrap">
                    <div class="text-sm text-gray-900">{{ item.title }}</div>
                  </td>
                  <td
                    class="px-6 py-3 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      class="btn btn-sm btn-square btn-ghost text-gray-500"
                      @click="editPlan(item)"
                    >
                      <i class="ri-pencil-line"></i>
                    </button>

                    <button
                      class="btn btn-sm btn-square btn-ghost text-gray-500"
                      @click="
                        dialogDelete(
                          'Eliminar Plan',
                          `¿Desea eliminar el plan ${item.title}?`,
                          'PLAN',
                          item
                        )
                      "
                    >
                      <i class="ri-delete-bin-line"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="rounded-lg border-gray-50 border border-gray-300 p-5 mt-4"
      v-if="view == 'ADD_PLAN' || view == 'UPDATE_PLAN'"
    >
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-2">
          <q-select
            v-model="plan.chef"
            :options="chefs"
            label="Chef"
            option-value="id"
            option-label="name"
            :rules="[(val) => !!val || 'Debes seleccionar el chef']"
          />
        </div>

        <div class="col-span-2">
          <q-input
            v-model="plan.title"
            label="Nombre"
            :rules="[(val) => !!val || 'Debes ingresar el nombre']"
          />
        </div>
      </div>
      <div class="px-4 py-6 text-center sm:px-6">
        <button
          type="button"
          v-if="view == 'ADD_PLAN'"
          @click="createPlan"
          class="btn btn-primary"
        >
          Guardar
        </button>
        <button
          type="button"
          v-else
          @click="updatePlan"
          class="btn btn-primary"
        >
          Editar
        </button>
      </div>
    </div>

    <dishes v-if="plan.id" ref="dishes" @updated="updated = true"></dishes>
    <!-- <extras
      :planId="plan.id"
      v-if="plan.id"
      ref="extras"
      @updated="updated = true"
    ></extras> -->
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import Dishes from './Dishes.vue';
// import Extras from './Extras.vue';
import { toastError, toastSuccess } from '../../js/util';
export default {
  components: {
    Dishes,
    // Extras,
  },
  data: function () {
    return {
      updated: false,
      plans: [],
      categories: [],
      tags: [],
      plan: {
        id: null,
        chef: null,
        type: null,
        title: null,
        description: null,
        price: 0,
        image: null,
        tags: [],
        idCategory: null,
      },
      dishes: [],
      view: 'PLANS',
      loading: false,
      chefs: [],
    };
  },
  created() {
    this.getPlans();
    this.getChefs();
  },

  methods: {
    getTitle() {
      if (this.view == 'PLANS') return 'Paquetes';
      if (this.view == 'ADD_PLAN') return 'Agregar Paquete';
      if (this.view == 'UPDATE_PLAN') return 'Editar Paquete';
    },

    getPlans() {
      this.updated = false;
      let formData = new FormData();
      formData.append('action', 'get_plans');
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.plans = res.data;
          } else toastError('Error al obtener los datos');
        })
        .catch(() => {
          toastError('Error al obtener los datos');
        });
    },
    getCurrentPlanChef() {
      let formData = new FormData();
      const { chef, type } = this.plan;
      formData.append('action', 'get_current_plan');
      formData.append('idChef', chef.id);
      formData.append('type', type);
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.code == 200) {
            this.plan = res.data.data;
            this.dishes = res.data.data?.dishes;
          }
        })
        .catch(console.log)
        .finally(() => (this.loading = false));
    },
    createPlan() {
      const { chef, type, title, description, price, image, tags, idCategory } =
        this.plan;
      console.log(this.plan);

      let formData = new FormData();
      formData.append('action', 'create_plan');
      formData.append('idChef', chef.id);
      formData.append('idCategory', idCategory);
      formData.append('type', type);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('price', price);
      formData.append('image', image);
      formData.append('tags', tags.map((item) => item.id).join(','));
      this.loading = true;
      console.log(JSON.stringify(tags));
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.getPlans();
            this.plan = {
              id: null,
              chef: null,
              type: null,
              title: null,
              description: null,
              price: 0,
              image: null,
              tags: [],
              idCategory: null,
            };
            this.view = 'PLANS';
            toastSuccess('Plan agregado correctamente');
          } else toastError();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          toastError();
        });
    },
    updatePlan() {
      const {
        chef,
        type,
        title,
        description,
        price,
        image,
        tags,
        idCategory,
        id,
        idProduct,
      } = this.plan;
      console.log(this.plan);

      let formData = new FormData();
      formData.append('action', 'update_plan');
      formData.append('id', id);
      formData.append('idChef', chef.id);
      formData.append('idCategory', idCategory);
      formData.append('type', type);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('price', price);
      formData.append('image', image);
      formData.append('idProduct', idProduct);
      formData.append('tags', tags.map((item) => item.id).join(','));
      this.loading = true;
      console.log(JSON.stringify(tags));
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.getPlans();
            this.plan = {
              id: null,
              chef: 0,
              type: null,
              title: null,
              description: null,
              price: 0,
              image: null,
              tags: [],
              idCategory: null,
            };
            this.view = 'PLANS';
            toastSuccess('Plan editado correctamente');
          } else toastError();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          toastError();
        });
    },

    getChefs() {
      let formData = new FormData();
      formData.append('action', 'get_chefs');
      this.loading = true;
      let context = this;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            context.chefs = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
          toastError();
        })
        .finally(() => (this.loading = false));
    },

    editPlan(item) {
      this.view = 'UPDATE_PLAN';

      this.plan = { ...item };
      this.plan.chef = this.chefs.find((chef) => chef.id == item.idChef);

      this.$nextTick(() => {
        this.$refs.dishes.edit(item);
      });
      // this.$nextTick(() => {
      //   this.$refs.extras.setExtras(item.extras || []);
      // });
    },
    deletePlan(item) {
      let formData = new FormData();

      formData.append('action', 'delete_plan');
      formData.append('id', item.id);
      formData.append('idProduct', item.idProduct);

      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200 && res.data === 'success') {
            this.getPlans();
            toastSuccess('Plan eliminado correctamente');
          } else toastError();
        })
        .catch((error) => {
          console.log(error);
          toastError();
        });
    },

    dialogDelete(title, message, action, item) {
      Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5850ec',
        cancelButtonColor: '#8e8e8e',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          if (action === 'DISH') {
            this.deleteDish(item.id);
          } else this.deletePlan(item);
        }
      });
    },

    cancelPlan() {
      if (this.updated) this.getPlans();
      this.view = 'PLANS';

      this.plan = {
        id: null,
        chef: null,
        type: null,
        title: null,
        description: null,
        price: 0,
        image: null,
        tags: [],
        idCategory: null,
      };
    },
  },
  watch: {},
};
</script>
