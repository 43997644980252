<template>
  <q-dialog v-model="modalDish">
    <q-card>
      <h3 class="font-bold text-lg mb-5">{{ title }}</h3>

      <q-form @submit="save">
        <q-input
          v-model="dish.name"
          label="Nombre"
          autofocus
          :rules="[(val) => !!val || 'Debes ingresar el nombre']"
        />

        <div class="flex mt-5">
          <h3 class="font-bold text-sm flex-1">Acompañamientos</h3>
          <div>
            <q-btn
              icon="o_close"
              outline
              class="mb-3"
              size="sm"
              @click="deleteAllAccompaniment"
            >
              Borrar todos
            </q-btn>
          </div>
        </div>

        <div v-for="(accompaniment, index) in dish.accompaniments" :key="index">
          <div class="flex">
            <q-input
              v-model="accompaniment.value"
              label="Nombre"
              :rules="[(val) => !!val || 'Debes ingresar el nombre']"
              class="flex-1"
              dense
              filled
              autofocus
            />
            <div>
              <q-btn
                icon="o_delete"
                round
                unelevated
                flat
                class="ml-3"
                size="sm"
                @click="deleteAccompaniment(index)"
              ></q-btn>
            </div>
          </div>
        </div>

        <div class="text-center">
          <q-btn @click="addAccompaniment">Agregar acompañamiento</q-btn>
        </div>

        <div class="modal-action">
          <q-btn type="submit" unelevated="" size="md" color="primary"
            >Aceptar</q-btn
          >
          <q-btn @click="modalDish = false" unelevated color="secondary"
            >Cerrar</q-btn
          >
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>
<script>
import MessagesMixin from '../../mixins/MessagesMixin';
import UtilMixin from '../../mixins/UtilMixin';
export default {
  mixins: [MessagesMixin, UtilMixin],
  data() {
    return {
      modalDish: false,
      dish: {},
      title: '',
    };
  },
  methods: {
    addDish() {
      this.dish = {
        title: '',
        accompaniments: [{ value: '' }],
        tempId: this.randomId(),
      };
      this.modalDish = true;
      this.title = 'Agregar Platillo';
    },
    editDish(dish) {
      this.dish = {
        ...dish,
        accompaniments: dish.accompaniments
          ?.split('||')
          .map((item) => ({ value: item })),
      };
      this.modalDish = true;
      this.title = 'Editar Platillo';
    },
    addAccompaniment() {
      if (this.dish.accompaniments)
        this.dish.accompaniments.push({ value: '' });
      else this.dish.accompaniments = [{ value: '' }];
    },
    save() {
      let dish = {
        ...this.dish,

        accompaniments: this.dish.accompaniments
          ?.map((item) => item.value)
          .join('||'),
      };
      this.modalDish = false;
      this.$emit('save', dish);
    },

    deleteAccompaniment(index) {
      this.dish.accompaniments.splice(index, 1);
    },
    deleteAllAccompaniment() {
      this.dish.accompaniments = [];
    },
  },
};
</script>
