<template>
  <div>
    <div v-if="view === 'CLIENTS'">
      <div class="flex mb-6">
        <h1 class="text-4xl font-bold mr-auto">Clientes</h1>
        <q-btn
          color="primary"
          unelevated
          icon="add"
          class="mr-3"
          @click="addPlan()"
        >
          Agregar paquete/plan
        </q-btn>
        <q-btn
          color="primary"
          unelevated
          icon="add"
          @click="$refs.modalAddCustomer.setVisibleModal(true)"
        >
          Agregar cliente
        </q-btn>
      </div>

      <q-table
        :rows="customers"
        :columns="columns"
        :filter="filter"
        :pagination="pagination"
        row-key="code"
        flat
        bordered
      >
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            placeholder="Buscar..."
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="code" :props="props">{{ props.row.code }}</q-td>
            <q-td key="firstName" :props="props">{{
              props.row.firstName
            }}</q-td>
            <q-td key="lastName" :props="props">{{ props.row.lastName }}</q-td>
            <q-td key="phone" :props="props">{{ props.row.phone }}</q-td>
            <q-td key="email" :props="props">{{ props.row.email }}</q-td>

            <q-td align="right">
              <q-btn
                size="sm"
                @click="selectCustomer(props.row)"
                color="grey-7"
                flat
                icon="o_shopping_cart"
              >
                <q-tooltip anchor="top middle" self="center middle">
                  Paquetes/Planes
                </q-tooltip>
              </q-btn>

              <q-btn
                color="grey-7"
                flat
                size="sm"
                icon="o_delete"
                @click="confirmDeleteCustomer(props.row)"
              >
                <q-tooltip anchor="top middle" self="center middle">
                  Eliminar
                </q-tooltip>
              </q-btn>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>

    <div v-else>
      <!--Planes por cliente-->
      <div v-if="view == 'PLANS'">
        <div class="text-right mb-3 flex justify-between">
          <h1 class="text-4xl font-bold mr-auto mb-6">
            {{ currentCustomer.firstName }}
            {{ currentCustomer.lastName }}
          </h1>
          <button class="btn mr-2" @click="view = 'CLIENTS'">Regresar</button>

          <!-- <button class="btn btn-primary" @click="addPlan">Agregar plan</button> -->
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto shadow-md sm:rounded-lg">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden">
                <table class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        class="py-3 px-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                      >
                        Paquete
                      </th>
                      <th
                        scope="col"
                        colspan="2"
                        class="py-3 px-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                      >
                        Plan
                      </th>
                      <th
                        scope="col"
                        colspan="2"
                        class="py-3 px-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                      >
                        Estado
                      </th>
                      <th
                        scope="col"
                        class="py-3 px-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                      >
                        Total
                      </th>
                      <th
                        scope="col"
                        class="py-3 px-3 text-xs font-medium tracking-wider text-right text-gray-700 uppercase"
                      >
                        Opciones
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                      class="hover:bg-gray-100"
                      v-for="item in plans"
                      :key="item.id"
                    >
                      <td
                        class="py-4 px-3 text-sm font-medium text-gray-700 whitespace-nowrap"
                      >
                        #{{ item.code }}
                      </td>
                      <td
                        colspan="2"
                        class="py-4 px-3 text-sm font-medium text-gray-700 whitespace-nowrap"
                      >
                        {{ item.product }}
                      </td>
                      <td
                        colspan="2"
                        class="py-4 px-3 text-sm font-medium text-gray-700 whitespace-nowrap"
                      >
                        {{ item.statusName }}
                      </td>
                      <td
                        class="py-4 px-3 text-sm font-medium text-gray-700 whitespace-nowrap"
                      >
                        <span
                          v-html="getFormatMoney(item.total, item.currency)"
                        ></span>
                      </td>

                      <td
                        class="py-4 px-3 text-sm font-medium text-right whitespace-nowrap"
                      >
                        <button
                          @click="showDetail(item)"
                          class="btn btn-sm btn-square btn-ghost text-gray-500"
                        >
                          <i class="ri-eye-line"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <detail-order
          :order="currentOrder"
          v-if="view == 'DETAIL'"
          @back="view = 'PLANS'"
          ref="detailOrder"
          :show-button-back="true"
        ></detail-order>
      </div>
    </div>
    <add-plan
      v-if="view == 'ADD_PLAN'"
      ref="addPlan"
      @back="view = 'PLANS'"
      @getData="getPlansByCustomer(currentCustomer.id)"
    ></add-plan>
    <modal-add-customer
      ref="modalAddCustomer"
      @customerAdded="getCustomers()"
    ></modal-add-customer>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import AddPlan from '../components/AddPlan.vue';
import DetailOrder from '../components/DetailOrder.vue';
import { formatDate, formatMoney, toastError, toastSuccess } from '../js/util';
import ModalAddCustomer from '../components/ModalAddCustomer.vue';

export default {
  components: {
    DetailOrder,
    AddPlan,
    ModalAddCustomer,
  },
  data: () => {
    return {
      url: null,
      view: 'CLIENTS',
      currentOrder: {},
      customers: [],
      plans: [],
      loading: false,
      currentCustomer: {},
      columns: [
        {
          name: 'code',
          label: 'Código',
          align: 'left',
          field: (row) => row.code,
        },
        {
          name: 'firstName',
          label: 'Nombre',
          align: 'left',
          field: (row) => row.firstName,
        },
        {
          name: 'lastName',
          label: 'Apellido',
          align: 'left',
          field: (row) => row.lastName,
        },
        {
          name: 'phone',
          label: 'Teléfono',
          align: 'left',
          field: (row) => row.phone,
        },
        {
          name: 'email',
          label: 'Email',
          align: 'left',
          field: (row) => row.email,
        },
        { name: 'options', label: 'Opciones', align: 'right' },
      ],
      filter: '',
      pagination: {
        rowsPerPage: 10,
      },
    };
  },
  created() {
    this.getCustomers();
  },
  methods: {
    selectCustomer(customer) {
      this.view = 'PLANS';
      this.currentCustomer = customer;
      this.getPlansByCustomer(customer.id);
    },
    getFormatMoney(value, currency) {
      return formatMoney(value, currency);
    },

    showDetail(order) {
      this.view = 'DETAIL';
      this.currentOrder = order;
      this.$nextTick(() => {
        this.$refs.detailOrder.setOrder(order);
      });
    },

    getCustomers() {
      let formData = new FormData();
      formData.append('action', 'get_users_plan');
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.customers = res.data;
          } else toastError('Error al obtener los datos');

          console.log(res);
        })
        .catch(console.log)
        .finally(() => (this.loading = false));
    },
    getPlansByCustomer(customerId) {
      let formData = new FormData();
      formData.append('action', 'get_plans_by_user');
      formData.append('customer_id', customerId);
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.plans = res.data;
          } else toastError('Error al obtener los datos');

          console.log(res);
        })
        .catch(console.log)
        .finally(() => (this.loading = false));
    },
    getFormatDate(date) {
      return formatDate(date);
    },
    confirmDeleteCustomer(customer) {
      Swal.fire({
        title: 'Eliminar cliente',
        text: `¿Quieres eliminar el cliente ${customer.name} ${customer.lastName}?`,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCustomer(customer);
        }
      });
    },
    deleteCustomer(customer) {
      let formData = new FormData();
      formData.append('action', 'delete_customer');
      formData.append('customer_id', customer.id);
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          this.loading = false;
          if (res.status == 200) {
            this.getCustomers();
            toastSuccess('Cliente eliminado correctamente');
          } else toastError('Error al eliminar el cliente');

          console.log(res);
        })
        .catch(() => {
          this.loading = false;
          toastError('Error al eliminar el cliente');
        });
    },

    addPlan() {
      this.$router.push('/plans/add');
    },
  },
};
</script>
