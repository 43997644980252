<template>
  <div>
    <h1 class="text-4xl font-bold mr-auto mb-6">Configuración de precios</h1>

    <q-table
      :rows="prices"
      :columns="columns"
      row-key="id"
      flat
      bordered
      :pagination="pagination"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="quantity" :props="props">
            {{ props.row.quantity }}
          </q-td>
          <q-td key="price" :props="props">
            <q-input
              filled
              type="number"
              step="0.1"
              dense
              v-model="props.row.price"
            ></q-input>
          </q-td>
          <q-td key="subsidized" :props="props">
            <q-input
              filled
              dense
              type="number"
              step="0.1"
              v-model="props.row.subsidized"
            ></q-input>
          </q-td>
          <q-td key="chef" :props="props">
            <q-input
              filled
              dense
              type="number"
              step="0.1"
              v-model="props.row.chef"
            ></q-input>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <div class="text-center mt-5">
      <q-btn
        label="Guardar"
        icon="o_save"
        color="primary"
        @click="updatePrices"
      ></q-btn>
    </div>
  </div>
</template>
<script>
import MessagesMixin from '../../mixins/MessagesMixin';
export default {
  mixins: [MessagesMixin],
  data() {
    return {
      prices: [],
      pagination: {
        rowsPerPage: 50,
      },
      columns: [
        {
          name: 'quantity',
          label: 'Cantidad',
          align: 'left',
          field: (row) => row.quantity,
        },
        {
          name: 'price',
          label: 'Platillo',
          align: 'left',
          field: (row) => row.price,
        },

        {
          name: 'subsidized',
          label: 'Subcidiado',
          align: 'left',
          field: (row) => row.subsidized,
        },
        {
          name: 'chef',
          label: 'Ganancia Chef',
          align: 'left',
          field: (row) => row.chef,
        },
      ],
    };
  },
  created() {
    this.getPrices();
  },
  methods: {
    async getPrices() {
      try {
        const { data } = await this.$axios.get('/plans/dishes/price');
        this.prices = data;
      } catch (error) {
        this.messageError('No se lograron obtener los precios');
      }
    },
    updatePrices() {
      this.$axios
        .put('/plans/dishes/price', this.prices)
        .then(({ data }) => {
          if (data.value > 0) this.messageSuccess('Precios actualizados');
          else this.messageError('No se lograron actualizar los precios');
        })
        .catch(() => {
          this.messageError('No se lograron actualizar los precios');
        });
    },
  },
};
</script>
