const originTypes = [
  {
    value: 'organic',
    label: 'Orgánico',
  },
  {
    value: 'ads',
    label: 'Pagado',
  },
];

const paymentMethods = [
  {
    label: 'Link de pago',
    value: 'cheque',
  },
  {
    label: 'Pago contra entrega',
    value: 'cod',
  },
  {
    label: 'Transferencia bancaria',
    value: 'bacs',
  },
];

const deliveryOptions = [
  {
    label: 'Dos envíos',
    value: '2',
  },
  {
    label: 'Un envío',
    value: '1',
  },
  {
    label: 'Envío gratis',
    value: '0',
  },
];

const departments = [
  {
    label: 'Guatemala',
    value: 'GU',
  },
];

export { originTypes, paymentMethods, deliveryOptions, departments };
