<template>
  <div>
    <p class="text-bold text-h6 q-mt-lg">Platillos</p>

    <q-table
      :rows="listDishWithTotals"
      :columns="columns"
      row-key="code"
      flat
      bordered
      :pagination="pagination"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="dish" :props="props">{{ props.row.dish }}</q-td>
          <q-td key="total" :props="props">{{
            $formatNumber(props.row.total + '')
          }}</q-td>
          <q-td key="percentage" :props="props"
            >{{ $formatNumber(props.row.percentage) }}%</q-td
          >
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
export default {
  props: {
    listDish: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 50,
      },
      columns: [
        {
          name: 'dish',
          label: 'No. Platillos',
          align: 'left',
          field: (row) => row.dish,
        },
        {
          name: 'total',
          label: 'Paquetes',
          align: 'left',
          field: (row) => row.total,
        },
        {
          name: 'percentage',
          label: 'Porcentaje',
          align: 'left',
          field: (row) => `${row.percentage}%`,
        },
      ],
      listDishWithTotals: [],
    };
  },
  methods: {
    getData() {
      this.listDishWithTotals = [...this.listDish];
      const total = this.listDishWithTotals.reduce(
        (acc, curr) => acc + curr.total,
        0
      );
      const percentage = this.listDishWithTotals.reduce(
        (acc, curr) => acc + +curr.percentage,
        0
      );
      this.listDishWithTotals.push({
        dish: 'Totales',
        total,
        percentage: `${percentage.toFixed(2)}`,
      });
    },
  },
  watch: {
    listDish() {
      this.getData();
    },
  },
};
</script>
