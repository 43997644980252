<template>
  <div>
    <q-form @submit="saveConfigurations">
      <packing-center ref="packingCenter"></packing-center>
      <parameters ref="parameters"></parameters>

      <div class="text-center mt-4">
        <q-btn
          color="primary"
          label="Guardar"
          icon="o_save"
          type="submit"
        ></q-btn>
      </div>
    </q-form>
  </div>
</template>
<script>
import PackingCenter from './PackingCenter.vue';
import Parameters from './Parameters.vue';
import MessagesMixin from '../../mixins/MessagesMixin';
export default {
  name: 'Configuration',
  mixins: [MessagesMixin],
  components: {
    PackingCenter,
    Parameters,
  },
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
    };
  },
  created() {
    this.fetchConfigurations();
  },
  methods: {
    fetchConfigurations() {
      let formData = new FormData();
      formData.append('action', 'get_configurations');

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.$refs.packingCenter.setData(res.data);
            this.$refs.parameters.setData(res.data);
          } else this.messageError('Error al obtener los datos');
        })
        .catch(() => {
          this.messageError('Error al obtener los datos');
        });
    },

    saveConfigurations() {
      let { deliverySubsidized, discountByRecurrence } =
        this.$refs.parameters.getData();

      let { address, phone, usePackingCenterInfo } =
        this.$refs.packingCenter.getData();

      let formData = new FormData();
      formData.append('action', 'save_configurations');
      formData.append('priceSubsidizedDelivery', deliverySubsidized);
      formData.append('discountByRecurrence', discountByRecurrence);

      formData.append('packingCenterAddress', address);
      formData.append('packingCenterPhone', phone);
      formData.append('usePackingCenter', usePackingCenterInfo);

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.messageSuccess('Datos guardados correctamente');
          } else this.messageError('Error al guardar los datos');
        })
        .catch(() => {
          this.messageError('Error al guardar los datos');
        });
    },
  },
};
</script>
