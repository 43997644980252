<template>
  <div>
    <div
      class="font-bold text-2xl text-left m-0 flex-1 bg-gray-100 pl-3 py-3 rounded-md m-3"
    >
      Detalle de precios
    </div>
    <table class="min-w-full divide-y divide-gray-200 px-3 py-3">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Cantidad
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Precio por platillo
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Pago al chef por platillo
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Precio subcidiado
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr
          v-for="item in detailData"
          :key="item.value"
          class="hover:bg-gray-100 dark:hover:bg-gray-700"
          style="border-bottom: 1px solid #eee"
        >
          <td class="px-6 py-3 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              {{ item.value }}
            </div>
          </td>

          <td class="px-6 py-3 whitespace-nowrap">
            <input
              type="number"
              min="0"
              max="1000"
              step="0.1"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-w-xs"
              required
              v-model="item.dishPrice"
            />
          </td>

          <td class="px-6 py-3 whitespace-nowrap">
            <input
              type="number"
              min="0"
              max="1000"
              step="0.1"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
              required
              v-model="item.chefAmount"
            />
          </td>
          <td class="px-6 py-3 whitespace-nowrap">
            <input
              type="number"
              min="0"
              max="1000"
              step="0.1"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
              required
              v-model="item.subsidyAmount"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <loading :show="isLoading"></loading>
  </div>
</template>
<script>
import { toastError } from '../js/util';
import Loading from './Loading.vue';
export default {
  props: {
    idType: {
      type: Number,
      required: true,
    },
  },
  components: {
    Loading,
  },
  data: () => ({
    isLoading: false,

    detailData: [],
  }),
  created() {
    if (this.idType > 0) {
      this.getDetail();
    } else this.getMaxValuePlans();
  },
  methods: {
    getDetailData() {
      return this.detailData;
    },
    getMaxValuePlans() {
      let formData = new FormData();
      formData.append('action', 'get_max_value_plans');
      this.isLoading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          console.log(res);
          this.detailData = [];
          this.maxValueUnitsPlans = Number(res.data.maxValue);
          for (let i = 0; i < this.maxValueUnitsPlans; i++) {
            this.detailData.push({
              id: 0,
              value: i + 1,
              dishPrice: 0,
              chefAmount: 0,
              subsidyAmount: 0,
            });
          }
        })
        .catch((error) => {
          toastError('Error al obtener los datos');
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },

    getDetail() {
      let formData = new FormData();
      formData.append('action', 'get_detail_type_plans');
      formData.append('idType', this.idType);
      this.isLoading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          console.log(res);
          this.detailData = [];
          this.detailData = res.data.map((item) => {
            return {
              id: item.id,
              value: item.value,
              dishPrice: item.dish_price,
              chefAmount: item.chef_amount,
              subsidyAmount: item.subsidy_amount,
            };
          });
        })
        .catch((error) => {
          toastError('Error al obtener los datos');
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
