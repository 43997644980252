<template>
  <div>
    <q-card flat bordered class="q-pa-md mt-4">
      <q-card-section>
        <div class="flex mb-4">
          <p class="text-h6 flex-1">Platillos</p>
          <div>
            <q-btn
              outline
              icon="o_add"
              @click="addDish"
              label="Agregar"
              class="mr-3"
            ></q-btn>
            <q-btn
              outline
              icon="o_close"
              @click="removeAllDish"
              label="Borrar todos"
              color="negative"
            ></q-btn>
          </div>
        </div>

        <q-table
          :rows="listDish"
          :columns="columns"
          row-key="id"
          flat
          bordered
          :pagination="pagination"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="no" :props="props">{{ props.rowIndex + 1 }}</q-td>
              <q-td key="name" :props="props">{{ props.row.name }}</q-td>
              <q-td key="accompaniments" :props="props">{{
                props.row.accompaniments?.split('||').join(', ')
              }}</q-td>

              <q-td align="right">
                <q-btn
                  size="sm"
                  @click="editDish(props.row)"
                  color="grey-7"
                  flat
                  icon="o_edit"
                >
                  <q-tooltip anchor="top middle" self="center middle">
                    Editar
                  </q-tooltip>
                </q-btn>

                <q-btn
                  color="grey-7"
                  flat
                  size="sm"
                  icon="o_delete"
                  @click="deleteDish(props.row)"
                >
                  <q-tooltip anchor="top middle" self="center middle">
                    Eliminar
                  </q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>

        <div class="text-center mt-5">
          <div>
            <q-btn @click="sendData" color="primary" label="Guardar"></q-btn>
          </div>
        </div>
        <div class="message">
          <q-icon name="o_info" class="mr-2"></q-icon>
          No olvides presionar "Guardar" despues de hacer tus cambios
        </div>
      </q-card-section>
    </q-card>
    <form-dish ref="formDish" :dish="currentDish" @save="saveDish"></form-dish>
  </div>
</template>
<script>
import MessagesMixin from '../../mixins/MessagesMixin';
import UtilMixin from '../../mixins/UtilMixin';
import FormDish from './FormDish.vue';
export default {
  mixins: [MessagesMixin, UtilMixin],
  components: {
    FormDish,
  },

  data() {
    return {
      pagination: {
        rowsPerPage: 10,
      },
      message: true,
      listDish: [],
      currentDish: {},
      planId: null,
      columns: [
        {
          name: 'no',
          label: 'No.',
          align: 'left',
        },
        {
          name: 'name',
          label: 'Nombre',
          align: 'left',
          field: (row) => row.name,
        },
        {
          name: 'accompaniments',
          label: 'Acompañamientos',
          align: 'left',
          field: (row) => row.accompaniments,
        },
        { name: 'options', label: 'Opciones', align: 'right' },
      ],
    };
  },
  methods: {
    edit(plan) {
      this.listDish = JSON.parse(JSON.stringify(plan.dishes));
      this.listDish = this.listDish.map((item) => ({
        ...item,
        tempId: this.randomId(),
      }));
      this.planId = plan.id;
    },
    addDish() {
      this.$refs.formDish.addDish();
      this.currentDish = {};
    },
    editDish(dish) {
      this.currentDish = { ...dish };
      this.$refs.formDish.editDish(dish);
    },
    deleteDish(dish) {
      let index = this.listDish.findIndex(
        (item) => item.tempId === dish.tempId
      );
      this.listDish.splice(index, 1);
    },
    saveDish(dish) {
      let index = this.listDish.findIndex((item) => item.tempId == dish.tempId);
      console.log('index', index, dish);
      if (index >= 0) {
        this.listDish[index] = dish;
        this.messageInfo('Platillo editado');
      } else {
        this.listDish.push(dish);
        this.messageInfo('Platillo agregado');
      }
    },

    sendData() {
      this.$axios
        .post('/plans/dishes', { dishes: this.listDish, planId: this.planId })
        .then((res) => {
          if (res.status == 200) {
            this.messageSuccess('Platillos guardados');
            this.$emit('updated');
          } else {
            this.messageError('No se ha logrado guardar los platillos');
          }
        })
        .catch(() => {
          this.messageError('Error al guardar los platillos');
        });
    },
    removeAllDish() {
      this.listDish = [];
    },
  },
  watch: {
    dishes: {
      handler: function (value) {
        console.log('udpate value disehs');
        if (value) {
          this.listDish = JSON.parse(JSON.stringify(value));
        } else {
          this.listDish = [];
        }
      },
    },
  },
};
</script>
<style>
.message {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #f0d05e;
  background-color: #f9cd2d1b;
  margin-top: 16px;
  font-size: 12px;
}
</style>
