import Vuex from 'vuex';

export default new Vuex.Store({
  state: {
    token: null,
    currentCustomer: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('tokenJwt', token);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem('tokenJwt');
    },
    setCurrentCustomer(state, customer) {
      state.currentCustomer = customer;
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token);
      localStorage.setItem('tokenJwt', token);
    },
    clearToken({ commit }) {
      commit('clearToken');
      localStorage.removeItem('tokenJwt');
    },
    initializeToken({ commit }) {
      const token = localStorage.getItem('tokenJwt');
      if (token) {
        commit('setToken', token);
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    token(state) {
      return state.token;
    },
    currentCustomer(state) {
      return state.currentCustomer;
    },
  },
});
