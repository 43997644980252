<template>
  <div>
    <p class="text-bold text-h6 q-mt-lg">Código de anuncios</p>

    <q-table
      :rows="listAds"
      :columns="columns"
      row-key="code"
      flat
      bordered
      :pagination="pagination"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="code" :props="props">{{ props.row.code }}</q-td>
          <q-td key="packages" :props="props">{{
            $formatNumber(props.row.packages)
          }}</q-td>
          <q-td key="customers" :props="props">{{
            $formatNumber(props.row.customers)
          }}</q-td>
          <q-td key="avgPacksByCustomer" :props="props">{{
            $formatNumber(props.row.packages / props.row.customers)
          }}</q-td>
          <q-td key="firstTransactions" :props="props">{{
            $formatNumber(props.row.firstTransactions)
          }}</q-td>
          <q-td key="amountSales" :props="props">{{
            $formatNumber(props.row.amountSales)
          }}</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
export default {
  props: {
    listAds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 50,
      },
      columns: [
        {
          name: 'code',
          label: 'Código',
          align: 'left',
          field: (row) => row.code,
        },
        {
          name: 'packages',
          label: 'Paquetes',
          align: 'left',
          field: (row) => row.packages,
        },
        {
          name: 'customers',
          label: 'Clientes',
          align: 'left',
          field: (row) => row.customers,
        },

        {
          name: 'avgPacksByCustomer',
          label: 'Promedio paquetes por cliente',
          align: 'left',
          field: (row) => row.packages / row.customers,
        },

        {
          name: 'firstTransactions',
          label: 'Primeras transacciones',
          align: 'left',
          field: (row) => row.firstTransactions,
        },
        {
          name: 'amountSales',
          label: 'Monto en ventas',
          align: 'left',
          field: (row) => row.amountSales,
        },
      ],
    };
  },
  methods: {},
};
</script>
