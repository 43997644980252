<template>
  <div>
    <h1 class="text-4xl font-bold mr-auto mb-6 mt-2">Inicio</h1>
    <filters @search="fetchMetrics"></filters>
    <div class="row q-col-gutter-md">
      <metric-item
        v-for="(item, index) in metrics.values"
        :key="index"
        :name="item.name"
        :value="item.value"
        class="col-4"
      ></metric-item>
    </div>

    <table-ads :list-ads="metrics.groupAds"></table-ads>
    <table-dishes :list-dish="metrics.groupDish"></table-dishes>
  </div>
</template>
<script>
import MetricItem from './MetricItem.vue';
import MessagesMixin from '../../mixins/MessagesMixin';
import TableAds from './TableAds.vue';
import TableDishes from './TableDishes.vue';
import Filters from './Filters.vue';

export default {
  mixins: [MessagesMixin],
  components: {
    MetricItem,
    TableAds,
    Filters,
    TableDishes,
  },
  data() {
    return {
      metrics: {},
    };
  },
  created() {
    this.fetchMetrics({});
  },
  methods: {
    fetchMetrics({ startDate, endDate }) {
      this.$axios
        .get('/plans/metrics', { params: { startDate, endDate } })
        .then(({ status, data }) => {
          if (status == 200) {
            this.metrics = data;
          }
        })
        .catch(() => {
          this.messageError('No se pudo obtener las métricas');
        });
    },
  },
};
</script>
