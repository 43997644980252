import dotenv from 'dotenv';
import 'flowbite';
import 'material-icons/iconfont/material-icons.css';
import Quasar from 'quasar/src/vue-plugin.js';;
import 'remixicon/fonts/remixicon.css';
import { createApp } from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import { axiosInit, axiosWpApi, setUrlBaseApi, setUrlBaseApiWp } from './axios';
import './index.css';
import quasarUserOptions from './quasar-user-options';
import router from './router';
import store from './store';

const envFile =
  process.env.NODE_ENV === 'production'
    ? '.env.production'
    : '.env.development';

dotenv.config({ path: envFile });

console.log('API url ', process.env.VUE_APP_URL_API_WP);

let app = createApp(App).use(Quasar, quasarUserOptions, Vuex);
app.use(router);

app.config.globalProperties.$formatNumber = function (value) {
  if (typeof value !== 'number') {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: value % 1 !== 0 ? 2 : 0,
  });

  return formatter.format(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

setUrlBaseApi();
setUrlBaseApiWp();

app.config.globalProperties.$http = axiosWpApi;
app.config.globalProperties.$axios = axiosInit;
app.mount('#app');
store.dispatch('initializeToken');
