<template>
  <q-card flat bordered class="q-pa-md">
    <q-card-section class="q-pb-none">
      <span class="text-h6">Cliente</span>
    </q-card-section>

    <q-card-section>
      <div class="q-col-gutter-md row">
        <q-select
          v-model="selectedCustomer"
          :options="filterCustomers"
          @filter="filterFn"
          label="Cliente"
          option-value="id"
          option-label="label"
          class="col-4"
          use-input
          input-debounce="0"
          :disable="customers.length == 0"
          :rules="[(val) => !!val || 'Debes seleccionar el cliente']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template></q-select
        >

        <q-input
          v-if="currentCustomer"
          v-model="currentCustomer.firstName"
          label="Nombre"
          :rules="[(val) => !!val || 'Debes ingresar el nombre']"
          class="col-4"
        ></q-input>

        <q-input
          v-if="currentCustomer"
          v-model="currentCustomer.lastName"
          label="Apellido"
          :rules="[(val) => !!val || 'Debes ingresar el apellido']"
          class="col-4"
        ></q-input>

        <q-input
          v-if="currentCustomer"
          v-model="currentCustomer.email"
          label="Correo electrónico"
          class="col-4"
        ></q-input>

        <q-input
          v-if="currentCustomer"
          v-model="currentCustomer.phone"
          label="Teléfono"
          :rules="[(val) => !!val || 'Debes ingresar el teléfono']"
          class="col-4"
          mask="####-####"
        ></q-input>

        <q-input
          v-if="currentCustomer"
          v-model="currentCustomer.nit"
          class="col-4"
          label="Nit"
        ></q-input>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
import MessagesMixin from '../../mixins/MessagesMixin';
import store from './AddPlanStore';
export default {
  mixins: [MessagesMixin],
  data: () => {
    return {
      store,
      selectedCustomer: null,
      currentCustomer: null,
      customers: [],
      filterCustomers: [],
    };
  },
  created() {
    this.getCustomers();
  },

  methods: {
    resetState() {
      this.currentCustomer = null;
      this.selectedCustomer = null;
    },
    sendData() {
      this.$emit('sendData', this.order);
    },
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.filterCustomers = this.customers.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    getCustomers() {
      let formData = new FormData();
      formData.append('action', 'get_users_plan');

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            let formattedCustomers = res.data.map((item) => ({
              ...item,
              label: `${item.code} - ${item.firstName} ${item.lastName} - ${item.email}`,
            }));
            this.customers = formattedCustomers;
            this.filterCustomers = formattedCustomers;
          } else this.messageError('Error al obtener los datos');
        })
        .catch(() => {
          this.messageError('Error al obtener los datos');
        });
    },

    getData() {
      return this.currentCustomer;
    },
  },
  watch: {
    selectedCustomer: {
      handler: function (newValue) {
        console.log('customer', newValue);
        this.currentCustomer = { ...newValue };
        store.commit('setCustomer', newValue);
      },
      deep: true,
    },
  },
};
</script>
