<template>
  <div
    class="q-pa-md"
    style="background-image: url('/statics/login-background.jpg')"
  >
    <div class="row justify-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="q-pa-md">
          <q-card class="q-mt-md">
            <q-card-section class="q-pt-lg q-pb-none">
              <div class="text-h6 text-center">Log In</div>
            </q-card-section>

            <q-separator />

            <q-card-section class="q-pt-none q-pb-lg">
              <q-form @submit="logIn">
                <q-input
                  outlined
                  v-model="email"
                  label="Email"
                  type="email"
                  class="mb-4"
                  :rules="[(val) => !!val || 'Please enter your email']"
                />

                <q-input
                  outlined
                  v-model="password"
                  label="Password"
                  type="password"
                  :rules="[(val) => !!val || 'Please enter your password']"
                />

                <q-btn
                  type="submit"
                  class="q-mt-md full-width"
                  color="primary"
                  label="Log In"
                />
              </q-form>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    login() {
      let formData = new FormData();
      formData.append('action', 'user_login');
      formData.append('password', this.password);
      formData.append('username', this.email);

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.data.success) {
            //set token
            console.log(res.data.data.token);

            store.commit('setToken', res.data.data.token);
            this.$router.push('/');
          } else this.toastError(res.data.data.message);

          console.log(res);
        })
        .catch(() => {
          this.toastError('Error al iniciar sesión');
        });
    },

    logIn() {
      this.$axios
        .post('/users/login', { email: this.email, password: this.password })
        .then(({ status, data }) => {
          if (status == 200) {
            store.commit('setToken', data.token);
            this.$router.push('/');
          }
        })
        .catch(() => {
          this.messageError('Error al iniciar sesión');
        });
    },
    toastError(message) {
      this.$q.notify({
        color: 'negative',
        message,
      });
    },
  },
};
</script>

<style></style>
