<template>
  <div>
    <p class="font-bold text-3xl text-left m-0 flex-1">Precio monto de envío</p>

    <div class="overflow-x-auto mt-4">
      <table class="table table-compact w-full divide-y divide-gray-200">
        <thead>
          <tr class="py-7">
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nombre municipio
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Precio
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr
            v-for="item in locations"
            :key="item.value"
            class="hover:bg-gray-100 dark:hover:bg-gray-700"
            style="border-bottom: 1px solid #eee"
          >
            <td class="font-normal">{{ item.label }}</td>
            <td>
              <input
                type="number"
                v-model="item.price"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                placeholder="Ingresa el precio"
                required
                step="0.01"
                min="0"
                max="999999999"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-5">
        <button class="btn btn-primary m-auto" @click="sendData">
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { toastError, toastSuccess } from '../js/util';
export default {
  data: () => {
    return {
      locations: [],
      loading: false,
    };
  },
  created() {
    this.getLocations();
  },
  methods: {
    // ...
    getLocations() {
      let formData = new FormData();
      formData.append('action', 'get_shipping_method');
      this.loading = true;
      this.$http
        .post(this.url, formData)
        .then((res) => {
          console.log(res);

          if (res.status == 200) {
            res.data.forEach((item) => {
              this.locations.push({
                value: item.id,
                label: item.location,
                price: parseFloat(item.price),
              });
            });
          } else {
            toastError('Se produjo un error al obtener los datos');
          }
        })
        .catch(console.log)
        .finally(() => {
          this.loading = false;
        });
    },

    sendData() {
      let formData = new FormData();
      formData.append('action', 'update_shipping_method');
      this.locations = this.locations.map((item) => {
        return {
          ...item,
          price: `${item.price}`.length == 0 ? 0 : item.price,
        };
      });
      formData.append('data', JSON.stringify(this.locations));
      this.loading = true;
      this.$http
        .post(this.url, formData)
        .then((res) => {
          console.log(res);

          if (res.status == 200) {
            toastSuccess('Se actualizó correctamente los precios');
          } else {
            toastError('Se produjo un error al actualizar los datos');
          }
        })
        .catch(console.log)
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    // ...
  },
  watch: {
    // ...
  },
};
</script>

<style></style>
