<template>
  <q-dialog v-model="isVisibleModal">
    <q-card>
      <h3 class="font-bold text-lg mb-5">Agregar cliente</h3>

      <q-form @submit="save" class="q-gutter-sm" style="width: 100%">
        <tabs variant="underline" v-model="activeTab" class="p-5">
          <tab name="first" title="Cliente existente">
            <q-select
              v-model="selectedCustomer"
              :options="filterCustomers"
              @filter="filterFn"
              label="Clientes"
              option-value="id"
              option-label="name"
              use-input
              input-debounce="0"
              :rules="[(val) => !!val || 'Debes seleccionar el cliente']"
            />
          </tab>
          <tab name="second" title="Nuevo cliente">
            <q-input
              v-model="newCustomer.name"
              label="Nombre"
              :rules="[(val) => !!val || 'Debes ingresar el nombre']"
            />
            <q-input
              v-model="newCustomer.lastName"
              label="Apellido"
              :rules="[(val) => !!val || 'Debes ingresar el apellido']"
            />

            <q-input
              v-model="newCustomer.email"
              type="email"
              label="Email"
              class="q-mb-md"
            />
            <q-input
              v-model="newCustomer.phone"
              label="Teléfono"
              :rules="[(val) => !!val || 'Debes ingresar el teléfono']"
              mask="####-####"
            />

            <q-input
              v-model="newCustomer.password"
              type="password"
              label="Contraseña"
              :rules="[(val) => !!val || 'Debes ingresar la contraseña']"
            />
          </tab>
        </tabs>
        <div class="modal-action">
          <q-btn type="submit" unelevated="" size="md" color="primary"
            >Guardar</q-btn
          >
          <q-btn @click="setVisibleModal(false)" unelevated color="secondary"
            >Cerrar</q-btn
          >
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { Tabs, Tab } from 'flowbite-vue';
import { toastError } from '../js/util';
import { Notify } from 'quasar';
export default {
  components: {
    Tabs,
    Tab,
  },
  data() {
    return {
      customers: [],
      filterCustomers: [],
      activeTab: 'first',
      selectedCustomer: null,
      isVisibleModal: false,
      newCustomer: {
        email: '',
        password: '',
        name: '',
        lastName: '',
        phone: '',
      },
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    setVisibleModal(value) {
      this.isVisibleModal = value;
    },
    getCustomers() {
      let formData = new FormData();
      formData.append('action', 'get_customers_without_plan');
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.customers = res.data.map((item) => ({
              id: item.id,
              name: `${item.firstName} ${item.lastName} - ${item.email}`,
            }));

            this.filterCustomers = res.data.map((item) => ({
              id: item.id,
              name: `${item.firstName} ${item.lastName} - ${item.email}`,
            }));
          } else toastError('Error al obtener los datos');
        })
        .catch(console.log);
    },
    save() {
      if (this.activeTab == 'first') {
        if (this.selectedCustomer.id > 0) this.addExistingCustomer();
        else toastError('Selecciona un cliente');
      } else {
        this.createCustomer();
      }
    },
    addExistingCustomer() {
      let formData = new FormData();
      formData.append('action', 'add_customer_to_plan');
      formData.append('customer_id', this.selectedCustomer.id);

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            Notify.create('Cliente agregado correctamente');
            this.$emit('customerAdded');
            this.isVisibleModal = false;
          } else toastError('Error al agregar el cliente');
        })
        .catch(() => {
          Notify.create('Se produjo un error al agregar el cliente');
        });
    },
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.filterCustomers = this.customers.filter(
          (v) => v.name.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    createCustomer() {
      //Add customer similar to addExistingCustomer
      let formData = new FormData();
      formData.append('action', 'create_customer_to_plan');
      formData.append('name', this.newCustomer.name);
      formData.append('last_name', this.newCustomer.lastName);
      formData.append(
        'email',
        this.newCustomer.email || 'clientes@kaserafood.com'
      );
      formData.append('phone', this.newCustomer.phone);
      formData.append('password', this.newCustomer.password);

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            Notify.create('Cliente agregado correctamente');
            this.$emit('customerAdded');
            this.isVisibleModal = false;
            this.newCustomer = {
              email: '',
              password: '',
              name: '',
              lastName: '',
            };
          } else toastError('Error al agregar el cliente');
        })
        .catch((error) => {
          Notify.create(
            error.response?.data?.data ||
              'Se produjo un error al agregar el cliente'
          );
        });
    },
  },
};
</script>

<style></style>
