<template>
  <div>
    <div class="text-right mb-3 flex">
      <p class="font-bold text-3xl text-left m-0 flex-1">Agregar paquete</p>
      <button type="button" @click="$emit('back')" class="btn">Regresar</button>
    </div>

    <div>
      <form class="pb-4" @submit="sendData">
        <div
          class="block p-6 bg-white rounded-lg border border-gray-300 mt-4 mr-2 flex-1"
        >
          <div class="flex">
            <div class="flex-1"></div>
            <div class="flex-1 mr-2">
              <q-select
                v-model="currentChef"
                label="Chef"
                :options="chefs"
                option-value="id"
                option-label="name"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No se encontraron resultados
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>

            <div class="flex-1 ml-2">
              <q-select
                v-model="currentPlan"
                label="Paquete"
                :options="getPlansByChef()"
                option-value="id"
                option-label="title"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No se encontraron resultados
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="flex-1"></div>
          </div>

          <div class="mt-3" v-for="(item, index) in packages" :key="item.id">
            <div class="my-3" v-if="packages.length > 1">
              <p class="font-bold text-lg">Paquete #{{ index + 1 }}</p>
            </div>
            <div>
              <ol class="flex flex-col space-y-4">
                <li
                  v-for="dish in item.dishes"
                  :key="dish.id"
                  class="flex cursor-pointer"
                >
                  <div>
                    <q-btn
                      outlined
                      round
                      size="sm"
                      @click="removeQuantityDish(dish)"
                      icon="o_remove"
                    ></q-btn>
                    <span class="mx-3">{{ dish.quantity }}</span>
                    <q-btn
                      outlined
                      round
                      icon="o_add"
                      size="sm"
                      @click="addQuantityDish(dish)"
                    ></q-btn>
                  </div>

                  <span class="flex flex-col cursor-pointer">
                    <span class="text-sm font-medium text-gray-900 ml-3">{{
                      dish.name
                    }}</span>
                    <label class="ml-3 text-sm text-gray-900 cursor-pointer">
                      {{ dish.accompaniments?.split('||').join(',') }}</label
                    >
                  </span>

                  <q-input
                    class="ml-3"
                    dense
                    filled
                    v-model="dish.comment"
                    label="Comentario"
                  />
                </li>
              </ol>
            </div>

            <div
              class="my-4 border border-gray-400"
              v-if="packages.length > 1"
            ></div>
          </div>

          <!-- <div class="my-3">
            <p class="font-bold text-center mb-4">Paquetes</p>
            <div class="flex justify-center items-center space-x-3">
              <button
                type="button"
                class="btn btn-circle btn-outline"
                @click="removePackage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="currentColor"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 13H5v-2h14v2z" />
                </svg>
              </button>
              <span class="font-bold text-lg">{{ quantity }}</span>
              <button
                type="button"
                class="btn btn-circle btn-outline"
                @click="addPackage"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="currentColor"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                </svg>
              </button>
            </div>
          </div> -->
          <div class="max-w-sm m-auto mt-5">
            <label class="block mb-2 text-sm font-medium text-gray-900"
              >Notas para el chef (opcional)</label
            >
            <textarea
              v-model="order.noteChef"
              class="mt-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>
        <div class="flex">
          <div
            class="pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8 block p-6 mt-4 bg-white rounded-lg border border-gray-300 flex-1 mr-2"
          >
            <h3 class="text-xl font-bold text-gray-900">
              Información de entrega
            </h3>
            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Nombre</label
              >
              <input
                type="text"
                v-model="order.firstName"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Ingresa el nombre"
                required
              />
            </div>
            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Apellido</label
              >
              <input
                type="text"
                v-model="order.lastName"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Ingresa el apellido"
                required
              />
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Correo electrónico</label
              >
              <input
                type="email"
                v-model="order.email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Ingresa el correo electrónico"
                required
                disabled
              />
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Teléfono</label
              >
              <input
                type="phone"
                v-model="order.phone"
                placeholder="Ingresa el número de teléfono"
                class="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Nit (opcional)</label
              >
              <input
                type="text"
                v-model="order.nit"
                placeholder="Ingresa el NIT "
                class="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
          </div>
          <div
            class="pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8 block p-6 mt-4 bg-white rounded-lg border border-gray-300 flex-1 ml-2"
          >
            <h3 class="text-xl font-bold text-gray-900 mt-2">
              Datos de entrega
            </h3>
            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Departamento</label
              >
              <select
                v-model="order.department"
                class="mt-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="GU" selected>Guatemala</option>
              </select>
            </div>
            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Municipio</label
              >
              <select
                v-model="order.municipality"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option
                  v-for="item in locations"
                  :key="item.value"
                  :value="item.label"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Dirección de entrega
              </label>
              <input
                type="text"
                v-model="order.address"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Ingresa la dirección de entrega"
                required
              />
            </div>
            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Notas de entrega (opcional)</label
              >
              <textarea
                type="text"
                v-model="order.noteDelivery"
                placeholder="Instrucciones especiales, codigos de acceso, etc."
                class="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              ></textarea>
            </div>

            <div>
              <label
                id="deliveryModal"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Fecha de entrega
              </label>
              <input
                type="date"
                v-model="order.dateDelivery"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Selecciona fecha de entrega"
                required
              />
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Hora de entrega
              </label>
              <div
                class="flex flex-wrap nowrap"
                style="flex-wrap: nowrap !important"
              >
                <select
                  required
                  v-model="order.deliveryTimeStart"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 mr-2"
                >
                  <option
                    v-for="item in getDeliveryTime()"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>

                <select
                  required
                  v-model="order.deliveryTimeEnd"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                >
                  <option
                    v-for="item in getDeliveryTime()"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="mt-3">
                {{ 'Rango de entrega:' + getRangeDeliveryTime() }}
              </div>
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Fecha y hora de entrega del Chef
              </label>
              <div
                class="flex flex-wrap nowrap"
                style="flex-wrap: nowrap !important"
              >
                <input
                  type="date"
                  v-model="order.dateDeliveryChef"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mr-2"
                  placeholder="Selecciona fecha de entrega"
                  required
                />

                <select
                  required
                  v-model="order.deliveryTimeChef"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 mr-2"
                >
                  <option
                    v-for="item in getDeliveryTime()"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>

            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900"
                >Método de pago
              </label>
              <select
                required
                v-model="order.paymentMethod"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
              >
                <option value="cheque">Link de pago</option>

                <option value="cod">Pago contra entrega</option>

                <option value="bacs">Transferecia bancaria</option>
              </select>
            </div>

            <div>
              <label
                id="deliveryModal"
                for="modalDeliveryDate"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Pendiente de pago
              </label>
              <input
                type="number"
                v-model="order.paymentPending"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder=""
              />
            </div>

            <q-select
              v-model="order.originType"
              label="Origen de adquisición"
              :options="originTypes"
              option-value="value"
              option-label="label"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    No se encontraron resultados
                  </q-item-section>
                </q-item>
              </template>
            </q-select>

            <q-input
              v-if="order.originType?.value == 'ads'"
              v-model="order.codeAds"
              label="Código de anuncio"
              :rules="[(val) => !!val || 'Debes ingresar el codgio de anuncio']"
            />

            <div class="flex items-center cursor-pointer">
              <q-checkbox
                name="is_plan"
                v-model="order.isRecurring"
                value="1"
                label="Es plan"
              />
              <i class="ri-information-line ml-3">
                <q-tooltip anchor="bottom middle" self="top middle">
                  Si es plan se le descontrá Q{{ discountByRecurrence }} a cada
                  platillo
                </q-tooltip>
              </i>
            </div>
          </div>
        </div>

        <div
          class="pb-4 space-y-4 lg:px-8 sm:pb-6 xl:pb-8 block p-6 mt-4 bg-white rounded-lg border border-gray-300"
        >
          <span
            class="rounded-lg items-center align-center flex bg-gray-100 p-3"
          >
            <div class="flex flex-1 mr-7 items-center">
              <div class="flex flex-1 items-center">
                <input
                  id="country-option-1"
                  type="radio"
                  name="countries"
                  value="2"
                  class="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                  aria-labelledby="country-option-1"
                  aria-describedby="country-option-1"
                  v-model="typeDelivery"
                />
                <label
                  for="country-option-1"
                  class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Dos envios
                </label>
              </div>
              <div class="flex flex-1 items-center">
                <input
                  id="country-option-2"
                  type="radio"
                  name="countries"
                  value="1"
                  checked
                  class="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                  aria-labelledby="country-option-2"
                  aria-describedby="country-option-2"
                  v-model="typeDelivery"
                />
                <label
                  for="country-option-2"
                  class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Un envío
                </label>
              </div>

              <div class="flex flex-1 items-center">
                <input
                  id="country-option-3"
                  type="radio"
                  name="countries"
                  value="0"
                  class="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
                  aria-labelledby="country-option-3"
                  aria-describedby="country-option-3"
                  v-model="typeDelivery"
                />
                <label
                  for="country-option-3"
                  class="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Envio gratis
                </label>
              </div>
            </div>
          </span>
          <div class="flex justify-between">
            <span class="text-grey-800 font-bold">Subtotal</span>
            <div v-if="isEditSubtotal" class="flex">
              <q-input
                label="Subtotal"
                dense
                type="number"
                v-model="priceDishes"
              ></q-input>
              <q-btn
                label="Aceptar"
                color="primary"
                class="ml-2"
                size="sm"
                unelevated
                @click="isEditSubtotal = !isEditSubtotal"
              ></q-btn>
            </div>
            <div v-else>
              <q-btn
                flat
                size="sm"
                color="secondary"
                icon="edit"
                round
                class="mr-2"
                @click="isEditSubtotal = !isEditSubtotal"
              ></q-btn>
              <span class="text-grey-800">{{ formatMoney(priceDishes) }}</span>
            </div>
          </div>
          <div class="flex justify-between">
            <span class="text-grey-800 font-bold">Costo de envío</span>
            <span class="text-grey-800">{{ formatMoney(priceDelivery) }}</span>
          </div>

          <div
            class="flex justify-between"
            v-if="order.isRecurring && order.discountByRecurring > 0"
          >
            <span class="text-grey-800 font-bold">Descuento por ser plan</span>
            <span class="text-grey-800">{{
              formatMoney(order.discountByRecurring)
            }}</span>
          </div>

          <div class="divider"></div>
          <div class="flex justify-between">
            <span class="text-grey-900 font-bold">Total</span>
            <span class="text-grey-800">{{ formatMoney(priceOrder) }}</span>
          </div>
        </div>

        <div class="flex items-center justify-center space-x-2 rounded-b">
          <button
            type="submit"
            class="btn bg-primary hover:bg-blue-800 border-0 btn-block max-w-sm rounded-full mt-5"
          >
            Finalizar pedido
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { formatMoney, toastError } from '../js/util';

import Swal from 'sweetalert2';
export default {
  data: () => {
    return {
      order: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        nit: null,
        address: null,
        department: 'GU',
        municipality: null,
        dateDelivery: {},
        dateDeliveryChef: {},
        password: null,
        noteDelivery: null,
        oneFreeDelivery: false,
        twoFreeDelivery: false,
        noteChef: null,
        deliveryTime: null,
        deliveryTimeEnd: null,
        deliveryTimeStart: null,
        deliveryTimeChef: null,
        paymentMethod: null,
        paymentPending: null,
        isRecurring: false,
        originType: null,
        codeAds: null,
        discountByRecurring: 0,
      },
      item: {
        dishes: [],
      },
      datesDelivery: [],
      chefs: [],
      loading: false,
      plans: [],
      allPlans: [],
      currentChef: null,
      currentPlan: null,
      locations: [],
      packages: [],
      quantity: 1,
      priceDishes: 0,
      priceDelivery: 0,
      priceOrder: 0,
      typeDelivery: '1',
      originTypes: [
        {
          value: 'organic',
          label: 'Orgánico',
        },
        {
          value: 'ads',
          label: 'Pagado',
        },
      ],
      discountByRecurrence: 0,
      dataPriceDishes: [],
      isEditSubtotal: false,
    };
  },
  created() {
    this.plans.length == 0 && this.getPlans();
    this.chefs.length == 0 && this.getChefs();
    this.locations.length == 0 && this.getLocations();
    this.discountByRecurrence == 0 && this.getDiscountRecurrence();
    this.dataPriceDishes.length == 0 && this.getDishPrices();

    this.setDefaultValues();
  },

  methods: {
    setDefaultValues() {
      const today = new Date();
      const nextMonday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + ((1 + 7 - today.getDay()) % 7)
      );
      this.order.dateDelivery = nextMonday.toISOString().slice(0, 10);
      this.order.dateDeliveryChef = nextMonday.toISOString().slice(0, 10);

      this.order.deliveryTimeStart = '11:00 am';
      this.order.deliveryTimeEnd = '1:00 pm';
    },
    setCustomer(customer) {
      this.order = {
        ...this.order,
        ...customer,
        originType: this.originTypes.find(
          (item) => item.value == customer.originType
        ),
      };
    },
    sendData(e) {
      e.preventDefault();
      if (!this.order.dateDelivery) {
        toastError('Selecciona una fecha de entrega');
        return;
      }
      let formData = new FormData();
      formData.append('action', 'create_order_plan');

      let { idProduct } = this.plans.find(
        (item) => item.id == this.currentPlan.id
      );
      let packages = this.packages.map((item) => {
        return {
          ...item,
          dishes: item.dishes
            .filter((elem) => elem.quantity > 0)
            .map((item) => {
              return {
                ...item,
                price: this.getPriceDish(),
              };
            }),
        };
      });
      let order = {
        ...this.order,
        deliveryAmount: this.priceDelivery,
        idChef: this.currentChef.id,
        deliveryTime: this.getRangeDeliveryTime(),
        total: this.priceDishes - this.order.discountByRecurring,
        originType: this.order.originType?.value,
        totalDishes: this.getQuantityDishes(),
        plan: {
          productId: idProduct,
          planId: this.currentPlan.id,
          quantity: this.quantity,
          price: this.getPriceDish(),
          packages: [...packages],
        },
      };

      console.log(order);
      formData.append('data', JSON.stringify(order));

      if (order.totalDishes == 0) {
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Debes seleccionar al menos un platillo',
          confirmButtonText: 'Cerrar',
        });
        return;
      }

      this.$http
        .post(this.url, formData)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.message === 'success') {
              Swal.fire({
                icon: 'success',
                title: `Paquete #${res.data.id}`,
                text: 'Paquete agregado correctamente',
                confirmButtonText: 'Cerrar',
              });
              this.$emit('back');
              this.$emit('getData');
              window.scrollTo(0, 0);
            } else {
              Swal.fire({
                icon: 'error',
                title: '',
                text: 'Lo sentimos, se ha producido un error al procesar la orden',
                confirmButtonText: 'Cerrar',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: 'Lo sentimos, se ha producido un error al procesar la orden',
              confirmButtonText: 'Cerrar',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'Lo sentimos, se ha producido un error',
            confirmButtonText: 'Cerrar',
          });
        })
        .finally(() => (this.loading = false));
    },
    formatMoney(value) {
      return formatMoney(value);
    },

    selectDate(date) {
      this.order.dateDelivery = date;
    },

    backDelivery() {
      this.isPayment = false;
    },
    getChefs() {
      let formData = new FormData();
      formData.append('action', 'get_chefs');
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.chefs = res.data;
          } else toastError('Error al obtener los chefs');
        })
        .catch(console.log);
    },

    getDiscountRecurrence() {
      let formData = new FormData();
      formData.append('action', 'get_discount_by_recurrence');
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.discountByRecurrence = res.data.value;
          } else toastError('Error al obtener datos');
        })
        .catch(console.log);
    },

    getDishPrices() {
      let formData = new FormData();
      formData.append('action', 'dish_prices');
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.dataPriceDishes = res.data;
          } else toastError('Error al obtener datos');
        })
        .catch(console.log);
    },
    getPlans() {
      let formData = new FormData();
      formData.append('action', 'get_plans');
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.plans = res.data.map((item) => {
              return {
                ...item,
                dishes: item.dishes.map((dish) => {
                  return { ...dish, quantity: 0, comment: null };
                }),
              };
            });
            this.allPlans = JSON.parse(JSON.stringify(this.plans));
          } else toastError('Error al obtener los planes');
        })
        .catch(console.log)
        .finally(() => (this.loading = false));
    },
    getLocations() {
      let formData = new FormData();
      formData.append('action', 'get_shipping_method');

      this.$http
        .post(this.url, formData)
        .then((res) => {
          if (res.status == 200) {
            res.data.forEach((item) => {
              this.locations.push({
                value: item.id,
                label: item.location,
                price: parseFloat(item.price),
              });
            });
            this.priceDelivery = this.getPriceDelivery(this.order.municipality);
          }
        })
        .catch(console.log);
    },
    getPlansByChef() {
      if (this.currentChef?.id > 0)
        return this.allPlans.filter(
          (item) => item.idChef == this.currentChef.id
        );
      else return [];
    },
    addPackage() {
      this.quantity++;

      let lastPackage = JSON.parse(
        JSON.stringify(this.packages[this.packages.length - 1])
      );
      this.packages.push({ ...lastPackage });
      this.calculatePrice();
    },
    removePackage() {
      if (this.packages.length > 1) {
        this.packages.pop();
        this.quantity--;
        this.calculatePrice();
      }
    },
    calculatePrice() {
      this.$nextTick(() => {
        this.priceDishes = this.getPriceDish() * this.getQuantityDishes();
        if (this.order.isRecurring) {
          this.order.discountByRecurring =
            this.getQuantityDishes() * this.discountByRecurrence;
        }
      });
    },

    getPriceDish() {
      let quantity = this.getQuantityDishes();
      if (!quantity) return 0;
      if (quantity > this.dataPriceDishes.at(-1).quantity) {
        return this.dataPriceDishes.at(-1).price;
      }

      return this.dataPriceDishes.find((item) => item.quantity == quantity)
        .price;
    },

    getQuantityDishes() {
      let counter = 0;

      this.packages.forEach((item) => {
        item.dishes
          .filter((item) => item.quantity > 0)
          .forEach((elem) => {
            counter += elem.quantity;
          });
      });

      return counter;
    },
    getPriceDelivery(newValue) {
      let location = this.locations.find((item) => item.label == newValue);
      if (location) return location.price;

      return 0;
    },
    getDeliveryTime() {
      let hours = [];

      for (let i = 0; i < 12; i++) {
        let hour = i === 0 ? 12 : i;
        for (let j = 0; j < 60; j += 60) {
          let minute = j.toString().padStart(2, '0');
          hours.push(`${hour}:${minute} am`);
        }
      }

      for (let i = 0; i < 12; i++) {
        let hour = i === 0 ? 12 : i;
        for (let j = 0; j < 60; j += 60) {
          let minute = j.toString().padStart(2, '0');
          hours.push(`${hour}:${minute} pm`);
        }
      }

      return hours;
    },
    getRangeDeliveryTime() {
      return `${this.order.deliveryTimeStart || ''} - ${
        this.order.deliveryTimeEnd || ''
      }`;
    },

    getRangeDeliveryTimeChef() {
      return `${this.order.deliveryTimeStartChef || ''} - ${
        this.order.deliveryTimeEndChef || ''
      }`;
    },

    addQuantityDish(dish) {
      dish.quantity++;
      this.calculatePrice();
    },

    removeQuantityDish(dish) {
      if (dish.quantity > 0) dish.quantity--;
      this.calculatePrice();
    },
  },
  watch: {
    currentChef: {
      handler: function () {
        this.plans = this.getPlansByChef();
      },
      deep: true,
    },
    currentPlan: {
      handler: function (newValue) {
        let item = this.plans.find((item) => item.id == newValue.id);
        this.packages.length = 0;
        this.packages.push({ dishes: item.dishes, price: item.price });
      },
      deep: true,
    },
    'order.municipality': {
      handler: function (newValue) {
        this.priceDelivery = this.getPriceDelivery(newValue);
      },
      deep: true,
    },
    priceDishes: {
      handler: function (newValue) {
        this.priceOrder =
          +newValue + this.priceDelivery - this.order.discountByRecurring;
      },
      deep: true,
    },
    priceDelivery: {
      handler: function (newValue) {
        this.priceOrder =
          newValue + this.priceDishes - this.order.discountByRecurring;
      },
      deep: true,
    },
    typeDelivery: {
      handler: function (newValue) {
        this.priceDelivery = this.getPriceDelivery(this.order.municipality);
        //Un envío gratis
        if (newValue == '2') this.priceDelivery = this.priceDelivery * 2;
        //Dos envio gratis
        else if (newValue == '0') this.priceDelivery = 0;
      },
      deep: true,
    },

    'order.paymentMethod': {
      handler: function (newValue) {
        if (newValue == 'cod') this.order.paymentPending = this.priceOrder;
      },
      deep: true,
    },
    'order.discountByRecurring': {
      handler: function (newValue) {
        this.priceOrder = this.priceDelivery + this.priceDishes - newValue;
      },
      deep: true,
    },
    'order.isRecurring': {
      handler: function (newValue) {
        if (newValue) {
          let counter = 0;

          this.packages.map((item) => {
            item.dishes.forEach((elem) => {
              if (elem.quantity > 0) {
                counter += elem.quantity;
              }
            });
          });
          if (counter > 0)
            this.order.discountByRecurring =
              counter * this.discountByRecurrence;
        } else this.order.discountByRecurring = 0;
      },
      deep: true,
    },
    'order.deliveryTimeStart': {
      handler: function (newValue) {
        let index = this.getDeliveryTime().findIndex(
          (item) => item == newValue
        );

        if (index > 0 && !this.order.deliveryTimeChef)
          this.order.deliveryTimeChef = this.getDeliveryTime()[index - 2];
      },
      deep: true,
    },
  },
};
</script>

<style></style>
