<template>
  <div>
    <div class="flex">
      <h3 class="font-bold text-2xl flex-1">
        Detalles del paquete #{{ currentOrder.code }}
      </h3>
      <button v-if="showButtonBack" class="btn" @click="$emit('back')">
        Regresar
      </button>
    </div>

    <div class="flex mt-2">
      <div class="border p-3 rounded-lg mr-3 flex-1">
        <p class="font-bold">Datos</p>
        <div class="flex flex-col space-y-1 pt-4">
          <p class="mb-0">
            <span class="font-semibold">Nombre</span>: {{ currentOrder.name }}
          </p>
          <p>
            <span class="font-semibold">Apellido</span>:
            {{ currentOrder.lastName }}
          </p>
          <p><span class="font-semibold">NIT</span>: {{ currentOrder.nit }}</p>
          <p>
            <span class="font-semibold">Email</span>: {{ currentOrder.email }}
          </p>
          <p>
            <span class="font-semibold">Departamento</span>:
            {{ currentOrder.department }}
          </p>
          <p>
            <span class="font-semibold">Municipio</span>:
            {{ currentOrder.municipality }}
          </p>
          <p>
            <span class="font-semibold">Dirección</span>:
            {{ currentOrder.address }}
          </p>

          <p>
            <span class="font-semibold">Fecha de entrega</span>:
            {{ getFormatDate(currentOrder.deliveryDate) }}
          </p>

          <p>
            <span class="font-semibold">Hora de entrega</span>:
            {{ currentOrder.deliveryTime }}
          </p>

          <p>
            <span class="font-semibold">Nota de enterga</span>:
            {{ currentOrder.noteDelivery }}
          </p>
        </div>
      </div>
    </div>

    <div class="bg-white border p-3 rounded-lg mt-3">
      <p class="py-4 font-bold text-lg mb-0">
        {{ currentOrder.product }}
      </p>

      <div v-for="(item, index) in getPackages()" :key="index">
        <div v-if="getPackages().length > 1">
          <p class="py-3 font-bold text-lg mb-0">Paquete #{{ index + 1 }}</p>
        </div>
        <ol style="list-style: square" class="space-y-3">
          <li v-for="item in item.dishes" :key="item.id" class="mx-5">
            <div class="flex justify-between items-center mb-2 ml-2">
              <div class="flex flex-col">
                <span class="font-medium"
                  >X {{ item.quantity }} {{ item.name }}</span
                >
                <span>{{ item.accompaniments?.split('||').join(',') }}</span>

                <div
                  class="p-1 mt-3 bg-gray-200 rounded-lg"
                  v-if="item.comment"
                >
                  <span class="font-medium">Comentario:</span>
                  {{ item.comment }}
                </div>
              </div>
              <span class="ml-3" style="white-space: nowrap">{{
                formatMoney(currentOrder.data.price * item.quantity)
              }}</span>
            </div>
            <div
              class="border border-solid border-gray-300 bg-gray-300 my-2"
            ></div>
          </li>
        </ol>
      </div>

      <div
        class="p-3 mt-3 bg-gray-200 rounded-lg"
        v-if="currentOrder.noteChef?.length > 0"
      >
        <p class="py-2 font-semibold mb-0">Nota para el chef</p>
        <p class="mb-0">{{ currentOrder.noteChef }}</p>
      </div>
    </div>

    <div class="border p-3 rounded-lg mt-3">
      <p class="py-2 flex justify-between mb-0">
        <span class="font-semibold">Subtotal</span
        ><span>{{
          formatMoney(
            currentOrder.total -
              currentOrder.amountShipping +
              (+currentOrder.discountByRecurring || 0)
          )
        }}</span>
      </p>
      <p class="py-2 flex justify-between mb-0">
        <span class="font-semibold">Envío</span
        ><span>{{ formatMoney(currentOrder.amountShipping) }}</span>
      </p>

      <p
        class="py-2 flex justify-between mb-0"
        v-if="currentOrder.discountByRecurring > 0"
      >
        <span class="font-semibold">Descuento por ser plan</span
        ><span>{{ formatMoney(currentOrder.discountByRecurring) }}</span>
      </p>
      <div class="border border-solid border-gray-300 bg-gray-300 my-2"></div>
      <p class="py-2 flex justify-between mb-0">
        <span class="font-bold">Total</span
        ><span class="font-bold">{{ formatMoney(currentOrder.total) }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { formatMoney, formatDate } from '../js/util';
export default {
  props: {
    showButtonBack: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      currentOrder: {},
    };
  },
  methods: {
    setOrder(order) {
      console.log(order);
      this.currentOrder = order;
    },
    formatMoney(value) {
      return formatMoney(value);
    },
    getPackages() {
      if (this.currentOrder.data) return this.currentOrder.data.packages;
      else return [];
    },
    getFormatDate(date) {
      return formatDate(date);
    },
  },
};
</script>
