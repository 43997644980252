<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <div>
    <h1 class="text-4xl font-bold mr-auto mb-6">Paquetes</h1>
    <div class="flex q-gutter-lg mb-4" style="align-items: self-end">
      <q-input
        label="Fecha inicio entrega"
        v-model="startDate"
        @click="$refs.startDatePicker.show()"
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              cover
              ref="startDatePicker"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date v-model="startDate" :locale="locale" mask="DD/MM/YYYY">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Cerrar" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <q-input
        label="Fecha fin entrega"
        v-model="endDate"
        @click="$refs.endDatePicker.show()"
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              ref="endDatePicker"
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date v-model="endDate" mask="DD/MM/YYYY" :locale="locale">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Cerrar" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <q-select
        v-model="chef"
        use-input
        input-debounce="0"
        label="Chef"
        :options="filterChefs"
        @filter="filterFn"
        option-value="id"
        option-label="name"
      >
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">
              No se encontraron resultados
            </q-item-section>
          </q-item>
        </template>
      </q-select>

      <q-btn
        color="primary"
        class="mr-auto"
        label="Buscar"
        icon="o_search"
        unelevated
        style=""
        @click="filterPackages()"
      />

      <div>
        <q-btn
          color="primary"
          label="Exportar para chefs"
          icon="o_file_download"
          @click="exportForChef"
          unelevated
          class="mr-3"
        />
        <q-btn
          color="primary"
          label="Exportar para repartidores"
          icon="o_file_download"
          @click="exportForDelivery"
          unelevated
        />
      </div>
    </div>

    <q-table
      :rows="packages"
      :columns="columns"
      :search="search"
      :pagination="pagination"
      row-key="id"
      flat
      bordered
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td>{{ props.row.code }}</q-td>
          <q-td>{{ props.row.product }}</q-td>
          <q-td>{{ props.row.totalDishes }}</q-td>
          <q-td>{{ props.row.statusName }}</q-td>
          <q-td>
            <span
              v-html="props.row.currency + $formatNumber(props.row.total)"
            ></span>
          </q-td>
          <q-td>{{ formatDate(props.row.deliveryDate) }}</q-td>
          <q-td align="right" @click="viewPackage(props.row)">
            <q-btn color="grey-7" size="sm" flat icon="o_visibility">
              <q-tooltip anchor="top middle" self="center middle">
                Ver
              </q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>

        <q-tr v-if="props.rowIndex == packagesWithTotal.length - 2">
          <q-td class="font-bold">Total</q-td>
          <q-td></q-td>
          <q-td class="font-bold">
            {{ $formatNumber(dishSum) }}
          </q-td>
          <q-td></q-td>
          <q-td>
            <span
              class="font-bold"
              v-html="props.row.currency + $formatNumber(totalSum)"
            ></span>
          </q-td>
          <q-td></q-td>
          <q-td></q-td>
        </q-tr>
      </template>
    </q-table>
    <modal-detail-order ref="modalDetailOrder"> </modal-detail-order>
  </div>
</template>

<script>
import moment from 'moment';
// import jsPDF from 'jspdf';
import 'jspdf-autotable';

import modalDetailOrder from '../../components/ModalDetailOrder.vue';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import DateMixin from '../../mixins/DateMixin';
import MessagesMixin from '../../mixins/MessagesMixin';
import {
  formatPackagesForChef,
  formatPackagesForDelivery,
} from './PackagesUtil';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    modalDetailOrder,
  },
  name: 'Packages',
  mixins: [DateMixin, MessagesMixin],
  data() {
    return {
      packages: [],
      columns: [
        { name: 'id', label: 'Paquete', align: 'left' },
        { name: 'product', label: 'Nombre', align: 'left' },
        { name: 'totalDishes', label: 'Platillos', align: 'left' },
        { name: 'statusName', label: 'Estado', align: 'left' },
        { name: 'total', label: 'Total', align: 'left' },
        { name: 'date', label: 'Fecha entrega', align: 'left' },
        { name: 'options', label: 'Opciones', align: 'right' },
      ],
      search: '',
      pagination: {
        rowsPerPage: 10,
      },

      startDate: null,
      endDate: null,

      chefs: [],
      filterChefs: [],
      chef: null,
      config: null,
    };
  },
  async created() {
    try {
      const today = new Date();
      const nextMonday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + ((1 + 7 - today.getDay()) % 7)
      );
      const dateDelivery = nextMonday
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '/');

      const nextSunday = new Date(
        nextMonday.getTime() + 6 * 24 * 60 * 60 * 1000
      );

      this.startDate = dateDelivery;
      this.endDate = nextSunday
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '/');

      this.filterPackages();
      this.getChefs();
    } catch (error) {
      this.toastError('Error al cargar los paquetes');
    }
  },
  methods: {
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.filterChefs = this.chefs.filter(
          (v) => v.name.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    async filterPackages() {
      const startDate = moment(this.startDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
      const endDate = moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      let formData = new FormData();
      formData.append('action', 'get_orders_with_plan');
      formData.append('start_date', startDate);
      formData.append('end_date', endDate);

      if (this.chef?.id > 0) {
        formData.append('chef_id', this.chef.id);
      }

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.packages = res.data;
          } else {
            this.toastError('Error al cargar los paquetes');
          }
        })
        .catch((error) => {
          console.log(error);
          this.toastError('Se ha producido un error');
        });
    },
    async viewPackage(item) {
      console.log(item);
      this.$refs.modalDetailOrder.show(item);
    },

    async exportForChef() {
      var docDefinition = {
        content: [
          {
            text: `Paquetes del ${this.startDate} al ${this.endDate}`,
            style: ['title'],
          },
          {
            ...(this.chef?.id > 0 && {
              text: `Chef: ${this.chef.name}`,
              bold: true,
            }),
          },
          ...formatPackagesForChef(this.packages),
        ],
        pageSize: {
          width: 595,
          height: 842,
        },
        styles: {
          title: {
            fontSize: 16,
            bold: true,
          },
          mb2: {
            margin: [0, 0, 0, 8],
          },
          titleItem: {
            bold: true,
            fontSize: 14,
            margin: [0, 20, 0, 8],
          },
          dishes: {
            bold: true,
            margin: [0, 20, 0, 10],
          },
        },
      };

      const pdfDoc = pdfMake.createPdf(docDefinition);

      pdfDoc.download(`Paquetes del ${this.startDate} al ${this.endDate}.pdf`);
    },

    async exportForDelivery() {
      let address;
      let phone;
      if (!this.config) {
        await this.fetchConfigurations();

        if (this.config.usePackingCenter) {
          address = this.config.packingCenterAddress;
          phone = this.config.packingCenterPhone;
        }
      }
      var docDefinition = {
        content: [
          {
            text: `Paquetes del ${this.startDate} al ${this.endDate}`,
            style: ['title', 'mb2'],
          },

          ...formatPackagesForDelivery(this.packages, address, phone),
        ],
        pageSize: {
          width: 595,
          height: 842,
        },
        styles: {
          title: {
            fontSize: 16,
            bold: true,
          },
          mb2: {
            margin: [0, 0, 0, 8],
          },
          mb1: {
            margin: [0, 0, 0, 4],
          },
          titleItem: {
            bold: true,
            fontSize: 14,
            margin: [0, 20, 0, 8],
          },
          dishes: {
            bold: true,
            margin: [0, 10, 0, 10],
          },
        },
      };

      const pdfDoc = pdfMake.createPdf(docDefinition);

      pdfDoc.download(`Paquetes del ${this.startDate} al ${this.endDate}.pdf`);
    },

    getChefs() {
      let formData = new FormData();
      formData.append('action', 'get_chefs');

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.chefs = res.data;
            this.filterChefs = res.data;
          } else this.toastError('Error al obtener los chefs');
        })
        .catch(() => {
          this.toastError('Se ha producido un error');
        });
    },

    async fetchConfigurations() {
      let formData = new FormData();
      formData.append('action', 'get_configurations');

      await this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.config = {
              ...res.data,
              usePackingCenter: res.data.usePackingCenter == 'true',
            };
          } else this.messageError('Error al obtener los datos');
        })
        .catch(() => {
          this.messageError('Error al obtener los datos');
        });
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },

  computed: {
    packagesWithTotal() {
      const packagesWithTotal = [...this.packages];
      const totalSum = packagesWithTotal.reduce(
        (total, packageItem) => total + packageItem.total,
        0
      );
      packagesWithTotal.push({
        code: '',
        product: '',
        totalDishes: '',
        statusName: '',
        total: totalSum,
        deliveryDate: '',
      });
      return packagesWithTotal;
    },
    totalSum() {
      return this.packages.reduce(
        (total, packageItem) => total + +packageItem.total,
        0
      );
    },
    dishSum() {
      return this.packages.reduce(
        (total, packageItem) => total + +packageItem.totalDishes,
        0
      );
    },
  },
};
</script>
