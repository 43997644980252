<template>
  <q-card flat bordered class="q-pa-md">
    <q-card-section>
      <p class="text-h6">Centro de empaque</p>
      <div class="row q-col-gutter-md">
        <q-input
          v-model="address"
          class="col-4"
          label="Direccion"
          :rules="[(val) => !!val || 'Debes ingresar el centro de empaque']"
        ></q-input>

        <q-input
          v-model="phone"
          class="col-4"
          mask="####-####"
          label="Teléfono"
          :rules="[(val) => !!val || 'Debes ingresar el teléfono']"
        ></q-input>

        <div class="flex items-center cursor-pointer col-4">
          <q-checkbox
            v-model="usePackingCenterInfo"
            value="1"
            label="Utilizar la info del centro de empaque al generar el PDF"
          />
          <i class="ri-information-line ml-3">
            <q-tooltip anchor="bottom middle" self="top middle">
              Cuando se exporta a PDF las ordenes se tomará la informacion del
              centro de empaque en lugar de la del chef
            </q-tooltip>
          </i>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
export default {
  data() {
    return {
      address: null,
      phone: null,
      usePackingCenterInfo: false,
    };
  },

  methods: {
    getData() {
      return {
        address: this.address,
        phone: this.phone,
        usePackingCenterInfo: this.usePackingCenterInfo,
      };
    },
    setData(data) {
      this.address = data.packingCenterAddress;
      this.phone = data.packingCenterPhone;
      this.usePackingCenterInfo = data.usePackingCenter === 'true';
    },
  },
};
</script>
