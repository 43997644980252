<template>
  <q-card flat bordered class="q-pa-md mt-4">
    <q-card-section class="q-pb-none">
      <span class="text-h6">Platillos</span>
    </q-card-section>

    <q-card-section>
      <div class="q-col-gutter-md row">
        <q-select
          v-model="currentChef"
          :options="filterChefs"
          @filter="filterChefFn"
          label="Chef"
          option-value="id"
          option-label="name"
          class="col-4"
          use-input
          input-debounce="0"
          :rules="[(val) => !!val || 'Debes seleccionar el chef']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template></q-select
        >

        <q-select
          v-model="currentPlan"
          label="Paquete"
          :options="getPlansByChef()"
          class="col-4"
          option-value="id"
          option-label="title"
          :rules="[(val) => !!val || 'Debes seleccionar el paquete']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No se encontraron resultados
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-input
          v-model="order.noteChef"
          class="col-4"
          label="Nota para el chef"
        ></q-input>
      </div>

      <div
        class=""
        v-for="(item, index) in store.getters.packages"
        :key="item.id"
      >
        <div class="my-3" v-if="store.getters.packages.length > 1">
          <p class="font-bold text-lg">Paquete #{{ index + 1 }}</p>
        </div>
        <div>
          <span class="text-caption"
            >Proteína <br />
            agrandada</span
          >
          <ol class="flex flex-col space-y-4">
            <div v-for="(dish, index) in item.dishes" :key="dish.id">
              <div class="flex cursor-pointer items-center">
                <q-checkbox
                  name="enlargedProtein"
                  v-model="dish.enlargedProtein"
                  value="1"
                  @update:model-value="handleCheckboxChange(dish)"
                />
                <q-btn
                  outlined
                  round
                  size="sm"
                  @click="removeQuantityDish(dish)"
                  icon="o_remove"
                ></q-btn>
                <span class="mx-3">{{ dish.quantity }}</span>
                <q-btn
                  outlined
                  round
                  icon="o_add"
                  size="sm"
                  @click="addQuantityDish(dish)"
                ></q-btn>

                <span
                  class="flex flex-col cursor-pointer"
                  style="min-width: 250px"
                >
                  <span class="ml-3 text-weight-bold"
                    >Opción {{ index + 1 }}</span
                  >
                  <div class="flex flex-row">
                    <span
                      class="text-sm text-weight-medium text-gray-900 ml-3"
                      >{{ dish.name }}</span
                    >
                    <label class="ml-3 text-sm text-gray-900 cursor-pointer">
                      {{ dish.accompaniments?.split('||').join(',') }}</label
                    >
                  </div>
                </span>

                <q-input
                  class="ml-3"
                  dense
                  filled
                  v-model="dish.comment"
                  label="Comentario"
                />
              </div>
              <q-separator v-if="index < item.dishes.length - 1" spaced="md" />
            </div>
            <q-separator style="width: 100px" />
            <div style="width: 100px" class="text-center text-bold">
              {{ store.getters.getQuantityDishes }}
            </div>
          </ol>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
import MessagesMixin from '../../mixins/MessagesMixin';
import store from './AddPlanStore';
export default {
  mixins: [MessagesMixin],
  components: {},
  data: () => ({
    store,
    currentChef: null,
    currentPlan: null,

    allPlans: [],
    plans: [],
    chefs: [],
    filterChefs: [],
    customers: [],
    order: {
      noteChef: '',
    },
    currentCustomer: null,
  }),
  created() {
    this.getPlans();
    this.getChefs();
  },
  methods: {
    resetState() {
      this.currentChef = null;
      this.currentPlan = null;

      this.order = {
        noteChef: '',
      };
      this.currentCustomer = null;
    },
    filterChefFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.filterChefs = this.chefs.filter(
          (v) => v.name.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    getPlans() {
      let formData = new FormData();
      formData.append('action', 'get_plans');

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.plans = res.data.map((item) => {
              return {
                ...item,
                dishes: item.dishes.map((dish) => {
                  return { ...dish, quantity: 0, comment: null };
                }),
              };
            });
            this.allPlans = JSON.parse(JSON.stringify(this.plans));
          } else this.messageError('Error al obtener los planes');
        })
        .catch(console.log);
    },

    addQuantityDish(dish) {
      dish.quantity++;
      store.dispatch('calculatePrice');
    },

    removeQuantityDish(dish) {
      if (dish.quantity > 0) dish.quantity--;

      store.dispatch('calculatePrice');
    },

    getPlansByChef() {
      if (this.currentChef?.id > 0)
        return this.allPlans.filter(
          (item) => item.idChef == this.currentChef.id
        );
      else return [];
    },
    getChefs() {
      let formData = new FormData();
      formData.append('action', 'get_chefs');

      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.chefs = res.data;
            this.filterChefs = res.data;
          } else this.messageError('Error al obtener los chefs');
        })
        .catch(() => {
          this.messageError('Error al obtener los chefs');
        });
    },

    getData() {
      return {
        noteChef: this.order.noteChef,
        customer: this.currentCustomer,
        chef: this.currentChef,
        plan: this.currentPlan,
      };
    },

    handleCheckboxChange(dish) {
      if (dish.enlargedProtein) {
        dish.comment = 'Proteína agrandada';
      } else {
        dish.comment = '';
      }
    },
  },
  computed: {
    customer() {
      return store.getters.customer;
    },
  },
  watch: {
    currentChef: {
      handler: function () {
        this.plans = this.getPlansByChef();
      },
      deep: true,
    },
    currentPlan: {
      handler: function (newValue) {
        store.commit('setPackages', []);
        if (newValue) {
          let item = this.allPlans.find((item) => item.id == newValue.id);

          store.commit('setPackages', [
            {
              dishes: JSON.parse(JSON.stringify(item.dishes)).map((item) => ({
                ...item,
                enlargedProtein: false,
              })),
              price: item.price,
            },
          ]);
        }
      },
      deep: true,
    },
    priceDishes: {
      handler: function (newValue) {
        console.log('priceDishes', newValue);
      },
      deep: true,
    },
    customer: {
      handler: function (newValue) {
        if (newValue) {
          this.currentChef = this.chefs.find(
            (item) => item.id == newValue.lastChefPlan
          );

          this.currentPlan = this.allPlans.find(
            (item) => item.idProduct == newValue.lastPlanProductId
          );
        }
      },
      deep: true,
    },
  },
};
</script>
