<template>
  <div>
    <div class="text-right mb-3 flex">
      <p class="font-bold text-3xl text-left m-0 flex-1">Tipos de planes</p>
      <button
        type="button"
        @click="add"
        v-if="view == 'LIST'"
        class="btn btn-primary"
      >
        Agregar
      </button>
    </div>

    <div class="flex flex-col">
      <div class="-my-2">
        <div class="py-2 min-w-full align-middle inline-block">
          <div
            class="shadow min-w-full overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table
              class="min-w-full divide-y divide-gray-200 px-3 py-3"
              v-if="view == 'LIST'"
            >
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Descripcion
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Opciones
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="item in types"
                  :key="item.id"
                  class="hover:bg-gray-100 dark:hover:bg-gray-700"
                  style="border-bottom: 1px solid #eee"
                >
                  <td class="px-6 py-3 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ item.description }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-3 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <button
                      class="text-indigo-600 hover:text-indigo-900"
                      @click="edit(item)"
                    >
                      Editar
                    </button>

                    <button
                      @click="
                        dialogDelete(
                          'Eliminar Tipo',
                          `¿Desea eliminar el tipo ${item.description}?`,
                          item
                        )
                      "
                      class="text-indigo-600 hover:text-blue-900 ml-3"
                      type="button"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <view v-else>
              <div class="grid grid-cols-6 gap-4 my-4">
                <div class="col-start-3 col-span-2">
                  <label class="block mb-2 text-sm font-medium text-gray-900"
                    >Descripción</label
                  >
                  <input
                    type="text"
                    v-model="type.description"
                    placeholder="Ingresa la descripción"
                    class="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
              </div>
              <type-plan-detail
                ref="typeDetailPlan"
                :idType="type.id"
              ></type-plan-detail>
              <div class="text-center my-5">
                <button
                  type="button"
                  @click="upsertType"
                  class="btn btn-primary"
                >
                  <span v-if="view === 'ADD'">Agregar</span>
                  <span v-else>Editar</span>
                </button>
                <button type="button" @click="view = 'LIST'" class="ml-3 btn">
                  Regresar
                </button>
              </div>
            </view>
          </div>
        </div>
      </div>
    </div>
    <loading :show="loading"></loading>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import Loading from '../components/Loading.vue';
import TypePlanDetail from '../components/TypePlanDetail';
import { toastError, toastSuccess } from '../js/util';
export default {
  components: {
    Loading,
    TypePlanDetail,
  },
  data: () => {
    return {
      loading: false,
      view: 'LIST',
      type: {
        id: 0,
        description: '',
      },
      types: [],
    };
  },
  created() {
    this.getTypes();
    this.type = {
      id: 0,
      description: '',
    };
    this.types = [];
  },
  methods: {
    deleteType() {
      let formData = new FormData();
      formData.append('action', 'delete_plans_type');
      formData.append('id', this.type.id);
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.view = 'LIST';
            toastSuccess(`Tipo de plan eliminado `);
            this.type = {
              description: '',
              id: 0,
            };
            this.getTypes();
          } else toastError('Error al eliminar el tipo de plan');

          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    upsertType() {
      let formData = new FormData();
      if (this.type.id == 0) formData.append('action', 'add_plans_type');
      else {
        formData.append('action', 'update_plans_type');
        formData.append('id', this.type.id);
      }
      formData.append(
        'detail',
        JSON.stringify(this.$refs.typeDetailPlan.getDetailData())
      );

      formData.append('description', this.type.description);
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.view = 'LIST';
            toastSuccess(
              `Tipo de plan ${this.type.id == 0 ? 'agregado' : 'editado'}`
            );
            this.type = {
              description: '',
              id: 0,
            };
            this.getTypes();
          } else
            toastError(
              `Error al ${
                this.type.id == 0 ? 'agregar' : 'editar'
              } el tipo de plan`
            );

          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getTypes() {
      let formData = new FormData();
      formData.append('action', 'get_plans_type');
      this.loading = true;
      this.$http
        .post('', formData)
        .then((res) => {
          if (res.status == 200) {
            this.types = res.data.map((item) => ({
              id: Number(item.id),
              description: item.description,
            }));
          } else toastError('Error al obtener los datos');

          console.log(res);
        })
        .catch(console.log)
        .finally(() => (this.loading = false));
    },
    dialogDelete(title, message, item) {
      Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5850ec',
        cancelButtonColor: '#8e8e8e',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.type = item;
          this.deleteType();
        }
      });
    },
    edit(item) {
      this.type = item;
      this.view = 'EDIT';
    },
    add() {
      this.type = {
        id: 0,
        description: '',
      };
      this.view = 'ADD';
    },
  },
};
</script>
