<template>
  <div class="drawer drawer-mobile bg-base-200 h-screen">
    <div class="drawer-side">
      <img
        width="150"
        class="ml-7"
        src="https://kaserafood.com/wp-content/uploads/2021/09/Logo-Kasera-Oficial.png"
      />
      <label for="my-drawer-2" class="drawer-overlay"></label>
      <ul class="menu p-4 w-80 text-base-content">
        <!-- Sidebar content here -->
        <li v-for="(item, index) in menu" :key="index">
          <a
            @click="toRouter(item.path, index)"
            :class="{ 'bg-primary text-white': item.active }"
          >
            <i
              :class="{
                'text-white': item.active,
                [item.icon]: true,
                'ri-lg': true,
              }"
            ></i>

            <span class="ml-3" :class="{ 'text-white': item.active }">
              {{ item.title }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Menu',
  data() {
    return {
      menu: [
        {
          path: '/',
          active: true,
          icon: 'ri-home-line',
          title: 'Inicio',
        },
        {
          path: '/plans',
          active: false,
          icon: 'ri-bar-chart-horizontal-line',
          title: 'Paquetes',
        },
        {
          path: '/customers',
          active: false,
          icon: 'ri-user-3-line',
          title: 'Clientes',
        },
        {
          path: '/packages',
          active: false,
          icon: 'ri-send-plane-line',
          title: 'Entregas',
        },

        {
          path: '/amount-delivery',
          active: false,
          icon: 'ri-truck-line',
          title: 'Precio de envío',
        },

        {
          path: '/configuration',
          active: false,
          icon: 'ri-settings-2-line',
          title: 'Configuración general',
        },

        {
          path: '/price-config',
          active: false,
          icon: 'ri-settings-line',
          title: 'Configuración de precios',
        },
        // {
        //   path: 'type-plan',
        //   active: false,
        //   icon: 'ri-bar-chart-horizontal-line',
        //   title: 'Tipos de planes',
        // },
      ],
    };
  },

  methods: {
    toRouter(route) {
      this.$router.replace(route);
    },
  },
  watch: {
    $route: function (to) {
      this.menu.forEach((item) => {
        if (`${item.path}` === to.path) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
  },
};
</script>
