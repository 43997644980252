import axios from 'axios';
import store from './store';

const axiosWpApi = axios.create({
  baseURL: process.env.VUE_APP_URL_API_WP,
});

export function setUrlBaseApiWp() {
  axiosWpApi.defaults.baseURL = process.env.VUE_APP_URL_API_WP;
}

// Add a request interceptor
axiosWpApi.interceptors.request.use(
  function (config) {
    showLoading(true);

    console.log(config);
    return config;
  },
  function (error) {
    console.log(error);
    showLoading(false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosWpApi.interceptors.response.use(
  function (response) {
    showLoading(false);
    console.log(response);
    return response;
  },
  function (error) {
    showLoading(false);
    console.log(error, error.response);
    return Promise.reject(error);
  }
);

const axiosInit = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});

export function setUrlBaseApi() {
  axiosWpApi.defaults.baseURL = process.env.VUE_APP_URL_API;
}

// Add a request interceptor
axiosInit.interceptors.request.use(
  function (config) {
    config.headers['country-id'] = process.env.VUE_APP_COUNTRY_ID;
    config.headers['Accept-Language'] = 'es';
    config.headers['Content-Type'] = 'application/json';

    //Auth bearer
    const token = localStorage.getItem('tokenJwt');
    if (token) config.headers['Authorization'] = `Bearer ${token}`;

    showLoading(true);

    console.log(config);
    return config;
  },
  function (error) {
    console.log(error);
    showLoading(false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInit.interceptors.response.use(
  function (response) {
    showLoading(false);
    console.log(response);
    return response;
  },
  function (error) {
    showLoading(false);

    if (!error.response) {
      store.commit('clearToken');

      window.location.reload();
      console.log('ERROR TOKEN', error);
    }
    return Promise.reject(error);
  }
);

const showLoading = (loading) => {
  const loadingEl = document.getElementById('loading');
  if (loading) loadingEl.style.display = 'flex';
  else loadingEl.style.display = 'none';
};

export { axiosInit, axiosWpApi };
