<template>
  <div>
    <q-card flat bordered class="q-pa-md" style="height: 100%">
      <q-card-section>
        <div class="">
          <p class="text-bold text-h6 mb-0 block">{{ $formatNumber(value) }}</p>

          <div class="text-secondary">{{ name }}</div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
